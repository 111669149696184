import React, { useCallback, useEffect, useState } from 'react';
import { GridFeatureModeConstant, GridFilterModel, GridSortModel, GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { Chip } from '../../../components/atoms/Chip';
import { TagCell } from '../../../components/molecules/TagCell';
import { ColumnSelectionItem } from '../../../components/templates/ColumnSelection';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { GridContainer } from '../../../styled/common.styles';
import { ActionPermissions, ColorProps, CustomPermission, RoleListResponse, ListType, PropReportDataType, NewReportDataType, ReportResponse, OverwriteReportDataType } from '../../../@types';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { useGetAllRoles } from '../../../queries/roles-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { Link } from '../../../components/atoms/Link';
import { useAddNewReport, useGetDefaultReportByReportType, useGetReportByCode, useUpdateReportByCode } from '../../../queries/report-query';
import { SavedReportTypeList } from '../../../configs/enums';
import { generateReportURL } from '../../../utils/report';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { PLATFORM_NAME } from '../../../configs/common';
import { DropdownItem } from '../../../components/atoms/SelectMenu';
import { useGetVisibilityRules } from '../../../queries/visibilityRules-query';

export const RoleList: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const reportCode = queryParams.get('savedReportCode');

  const [reportViewCode, setReportViewCode] = useState<string>();
  const [reportColumnSelectionItems, setReportColumnSelectionItems] = useState<Array<ColumnSelectionItem>>();
  const [reportName, setReportName] = useState('');
  const [newReportData, setNewReportData] = useState<PropReportDataType>();
  const [savedReportMessage, setSavedReportMessage] = useState('');
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [roles, getRoles] = useState<RoleListResponse>();
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [fetchDataGrid, setFetchDataGrid] = useState<boolean>(false);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [visibilityRuleList, setVisibilityRuleList] = useState<DropdownItem[]>([]);

  const useAddNewReportQuery = useAddNewReport();
  const useOverwriteReportQuery = useUpdateReportByCode(reportCode || '');
  const { data: reportQueryData, refetch: refetchGetReportByCodeQuery } = useGetReportByCode(reportCode || '');
  const defaultReportData = useGetDefaultReportByReportType(SavedReportTypeList.RoleList);
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const getAllRoles = useGetAllRoles({ filterModel, page, pageSize, sortModel });
  const visibilityRulesQuery = useGetVisibilityRules();

  useEffect(() => {
    if (visibilityRulesQuery.data) {
      const visibilityRuleItems = visibilityRulesQuery?.data?.map((visibilityRule) => ({
        value: visibilityRule.id.toString(),
        label: visibilityRule.name
      })) || [];

      setVisibilityRuleList(visibilityRuleItems);
    }
  }, [visibilityRulesQuery.data]);

  useEffect(() => {
    setColumns([
      {
        field: 'id',
        headerName: 'Role ID',
        width: 100,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value.toString()
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 300,
        renderCell: (params: GridRenderCellParams) => 
          isUserHasPermission(ActionPermissions.Configure_User_Roles_Edit_General, permissions) ?
            <Link href={`/configure/users/roles/${params.row.id}`}>{params.row.name}</Link> :
            params.row.name
      },
      {
        field: 'tags',
        headerName: 'Tags',
        width: 300,
        renderCell: (params: GridRenderCellParams) => <TagCell tags={JSON.parse(params.row.tags)}/>
      },
      {
        field: 'notes',
        headerName: 'Notes',
        type: 'string',
        width: 200
      },
      {
        field: 'visibilityRuleName',
        headerName: 'Visibility Rule',
        type: 'singleSelect',
        width: 200,
        valueOptions: visibilityRuleList
      },
      {
        field: 'isActive',
        headerName: 'Active',
        type: 'singleSelect',
        width: 100,
        valueOptions: [
          { value: 'any', label: 'Any' },
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ],
        renderCell: (params: GridRenderCellParams) => params.row.isActive ? <Chip label="YES" color={ColorProps.Success} /> : <Chip label="NO" color={ColorProps.Error} />
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        type: 'date',
        width: 180
      },
      {
        field: 'createdByName',
        headerName: 'Created By',
        type: 'string',
        width: 150
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        type: 'date',
        width: 180
      },
      {
        field: 'updatedByName',
        headerName: 'Updated By',
        type: 'string',
        width: 150
      },
    ]);
  }, [permissions, visibilityRuleList]);
 
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel({ ...filterModel });
  }, []);
  
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  useEffect(() => {
    !reportCode && defaultReportData.refetch();
  }, [SavedReportTypeList.RoleList]);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    if (!reportCode && defaultReportData.data && defaultReportData.data?.length > 0) {
      setFilterModel(defaultReportData.data[0].dataGridProperties?.filterModel);
      setSortModel(defaultReportData.data[0].dataGridProperties?.sortModel);
      setReportColumnSelectionItems(defaultReportData.data[0].columnSelection);
      setPageSize(defaultReportData.data[0].dataGridProperties?.pageSize);
    }   
  }, [defaultReportData.data, reportCode]);

  useEffect(() => {
    if (reportCode) {
      refetchGetReportByCodeQuery();
      setReportViewCode(reportCode);
    }
  }, [reportCode, fetchDataGrid]);

  useEffect(() => {
    if (reportCode && reportQueryData) {
      document.title = `${reportQueryData.name} (${reportCode}) | List Roles | ${PLATFORM_NAME}`;
      
      setFilterModel(reportQueryData.dataGridProperties?.filterModel);
      setSortModel(reportQueryData.dataGridProperties?.sortModel);
      setReportColumnSelectionItems(reportQueryData.columnSelection);
      setPageSize(reportQueryData.dataGridProperties?.pageSize);
      setReportName(reportQueryData.name);
      setIsDefault(reportQueryData.isDefault);
    }   
  }, [reportQueryData, reportViewCode]);

  useEffect(() => {
    setNewReportData({
      dataGridProperties: {
        filterModel: filterModel,
        pageSize: pageSize,
        sortModel: sortModel || []
      },
      reportTypeName: SavedReportTypeList.RoleList,
      reportName: reportName,
      isDefault: reportQueryData?.isDefault,
      id: reportQueryData?.id,
      reportOwner: reportQueryData?.createdBy,
      columnSelection: reportColumnSelectionItems
    });
  }, [filterModel, pageSize, isDefault, reportName, sortModel]);

  useEffect(() => {
    getAllRoles.data && getRoles(getAllRoles.data);
  }, [getAllRoles.data]);

  useEffect(() => {
    if (!reportCode || (reportCode && reportQueryData)) {
      getAllRoles.refetch();  
    }
  }, [filterModel, page, pageSize, isDefault, sortModel]);

  const handleColumnsChange = (items: Array<ColumnSelectionItem>) => {
    setReportColumnSelectionItems(items);
  };

  const savedReport = async (newReportData: NewReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useAddNewReportQuery.mutateAsync(newReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Created');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  const overwriteReport = async (overwriteReportData: OverwriteReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useOverwriteReportQuery.mutateAsync(overwriteReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Updated');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true); 
    navigate(reportURL, { replace: true });
  };
  
  const columnSelectionItems: Array<ColumnSelectionItem> = [
    {
      title: 'Role',
      value: [
        {
          title: 'Role ID',
          field: 'id',
          value: true
        },
        {
          title: 'Name',
          field: 'name',
          value: true
        },
        {
          title: 'Tags',
          field: 'tags',
          value: true
        },
        {
          title: 'Notes',
          field: 'notes',
          value: true
        },
        {
          title: 'Visibility Rule',
          field: 'visibilityRuleName',
          value: true
        },
        {
          title: 'Active',
          field: 'isActive',
          value: true
        },
        {
          title: 'Created At',
          field: 'createdAt',
          value: true,
        },
        {
          title: 'Created By',
          field: 'createdByName',
          value: true
        },
        {
          title: 'Updated At',
          field: 'updatedAt',
          value: true
        },
        {
          title: 'Updated By',
          field: 'updatedByName',
          value: true
        },
      ]
    }
  ];

  return (
    <GridContainer>
      <CustomDataGrid
        columns={columns}
        rows={roles?.data || []}
        filterModel={filterModel}
        rowCount={roles?.total}
        exportFileName={ListType.RoleList}
        enableCSVExport={isUserHasPermission(ActionPermissions.Configure_User_Roles_Export, permissions)}
        columnSelectionItems={reportColumnSelectionItems ? reportColumnSelectionItems : columnSelectionItems}
        filterMode={GridFeatureModeConstant.server}
        onFilterModelChange={(filterModel: GridFilterModel) => {
          onFilterChange(filterModel);
        }}
        onSortModelChange={handleSortModelChange}
        loading={getAllRoles.isLoading}
        rowsPerPageOptions={[25, 50, 100]}
        pagination={true}
        page={page}
        pageSize={pageSize}
        paginationMode={GridFeatureModeConstant.server}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        componentsProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true }
          }
        }}
        newReportData={newReportData} 
        handleSavedReport={(newReportData: NewReportDataType) => savedReport(newReportData)}
        handleOverwriteReport={(overwriteReportData: OverwriteReportDataType) => overwriteReport(overwriteReportData)}
        enableSavedReport={true}
        reportCode={reportViewCode}
        handleColumnsChange={handleColumnsChange}
        handleRefetch={() => setFetchDataGrid(!fetchDataGrid)}
        getRowHeight={() => 'auto'}
        sortModel={sortModel}
        sortingMode={GridFeatureModeConstant.server}
      />
      <Grid>
        <Snackbar  
          open={openToast} 
          autoHideDuration={4000} 
          message={savedReportMessage ? savedReportMessage : 'Successfully Created'}
          onClose={()=> setOpenToast(false)}
        />
      </Grid>
    </GridContainer>
  );
};