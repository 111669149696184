import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import { 
  GridColDef,
  GridFeatureModeConstant,
  GridFilterModel,
  GridRenderCellParams,
  GridSelectionModel,
  GridSortModel,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid-pro';
import IconButton from '@mui/material/IconButton';
import { Edit } from '@mui/icons-material';
import { NotificationRuleListFragmentProps } from './NotificationRuleListFragment.props';
import { CustomDataGrid } from '../../../../components/layouts/CustomDataGrid';
import { DropdownItem, SelectMenu } from '../../../../components/atoms/SelectMenu';
import { NotificationConfigType } from '../../../../configs/enums';
import { NotificationConfigObject } from '../../../../@types';
import { NotificationRulesConfigModelFragment } from '../NotificationRuleConfigModelFragment/NotificationRuleConfigModelFragment';
import { getDataGridListColumnNames } from '../../../../utils/array-utils';
import { getNotificationListColumnLabel } from '../../../../utils/notification';
import { useGetNotificationConfigsByCateogry } from '../../../../queries/notification-config-query';
import { NOTIFICATION_EVENTS, NOTIFICATION_MESSAGE_TYPES, NOTIFICATION_CONTACT_RECORD_TYPES } from '../../../../constants/notification.constant';
import { GridContainer } from '../../../../styled/common.styles';
import { ISACTIVE_FILTER_DROPDOWN_ITEM_ARRAY } from '../../../../constants/common';
import { serviceColumnSelectionItems, shipmentColumnSelectionItems } from '../../../../constants/notificationRule';

export const NotificationRuleListFragment: React.FC<NotificationRuleListFragmentProps> = ({
  modelData,
  refetchData,
  editPermission
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [rowCountState, setRowCountState] = useState(0);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [selectedNotificationType, setSelectedNotificationType] = useState<string>('-1');
  const [notificationConfigData, setNotificationConfigData] = useState<NotificationConfigObject[]>([]);
  const [editConfigData, setEditConfigData] = useState<NotificationConfigObject>();
  const [openModel, setOpenModel] = useState(false);

  const { data: notificationConfig, refetch: refetchConfigData, isLoading, isRefetching } = useGetNotificationConfigsByCateogry(selectedNotificationType, { filterModel, page, pageSize, sortModel });

  useEffect(() => {
    selectedNotificationType !== '-1' && refetchConfigData();
  }, [selectedNotificationType, filterModel, page, pageSize, sortModel, refetchData]);

  useEffect(() => {
    setNotificationConfigData(notificationConfig?.data || []);
    setRowCountState(notificationConfig?.total || 0);
  }, [notificationConfig]);

  const changeType = (type: string) => {
    setNotificationConfigData([]);
    setSelectedNotificationType(type);
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel({ ...filterModel });
  }, []);
  
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  }, []);

  const closeModel = () => {
    refetchConfigData();
    setOpenModel(false);
  };

  const notificationTypes: Array<DropdownItem> = [
    { value: NotificationConfigType.Service, label: NotificationConfigType.Service },
    { value: NotificationConfigType.Shipment, label: NotificationConfigType.Shipment }
  ];

  useEffect(() => {
    if(selectedNotificationType === NotificationConfigType.Service) {
      setColumns([
        {
          field: 'id',
          headerName: 'ID',
          flex: 0.05,
          type: 'number',
          valueFormatter: (params: GridValueFormatterParams) => params.value.toString()
        },
        {
          field: 'name',
          headerName: 'Name',
          flex: 0.8,
          valueGetter: (params: GridValueGetterParams) => params.row.name,
        },
        {
          field: 'clientcontract',
          headerName: 'Client/Contract',
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => params.row.clientContractHierarchy,
        },
        {
          field: 'serviceType',
          headerName: 'Service Type',
          flex: 0.65,
          valueGetter: (params: GridValueGetterParams) => getDataGridListColumnNames(modelData?.serviceActionProcessTypes?.serviceTypes, params, 'serviceType')
        },
        {
          field: 'actionType',
          headerName: 'Action Type',
          flex: 0.6,
          valueGetter: (params: GridValueGetterParams) => getDataGridListColumnNames(modelData?.serviceActionProcessTypes?.actionTypes, params, 'actionType')
        },
        {
          field: 'processType',
          headerName: 'Process Type',
          flex: 0.6,
          valueGetter: (params: GridValueGetterParams) => getDataGridListColumnNames(modelData?.serviceActionProcessTypes?.processTypes, params, 'processType')
        },
        {
          field: 'priority',
          headerName: 'Priority',
          flex: 0.6,
          valueGetter: (params: GridValueGetterParams) => params.row.priorityCodes
        },
        {
          field: 'region',
          headerName: 'Region',
          flex: 0.6,
          valueGetter: (params: GridValueGetterParams) => getDataGridListColumnNames(modelData?.regionTypes, params, 'region')
        },
        {
          field: 'status',
          headerName: 'Status',
          flex: 0.7,
          valueGetter: (params: GridValueGetterParams) => getDataGridListColumnNames(modelData?.serviceStatuses, params, 'status')
        },
        {
          field: 'event',
          headerName: 'Event',
          flex: 0.7,
          valueGetter: (params: GridValueGetterParams) => getDataGridListColumnNames(NOTIFICATION_EVENTS, params, 'event')
        },
        {
          field: 'messageType',
          headerName: 'Message Type',
          flex: 0.8,
          valueGetter: (params: GridValueGetterParams) => getNotificationListColumnLabel(NOTIFICATION_MESSAGE_TYPES, params.row.messageType),
        },
    
        {
          field: 'contactRecordType',
          headerName: 'Contact Record Type',
          flex: 1.2,
          valueGetter: (params: GridValueGetterParams) => getNotificationListColumnLabel(NOTIFICATION_CONTACT_RECORD_TYPES, params.row.contactRecordType),
        },
        {
          field: 'template',
          headerName: 'Template',
          flex: 0.8,
          valueGetter: (params: GridValueGetterParams) => params.row.templateName,
        },
        {
          field: 'emailAddresses',
          headerName: 'Email Addresses',
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => params.row.emailAddresses,
        },
        {
          field: 'phoneNumbers',
          headerName: 'Phone Numbers',
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => params.row.phoneNumbers,
        },
        {
          field: 'considerTradingHours',
          headerName: 'Consider Traing Hours',
          flex: 0.5,
          type: 'singleSelect',
          valueOptions: ISACTIVE_FILTER_DROPDOWN_ITEM_ARRAY,
          valueGetter: (params: GridValueGetterParams) => params.row.considerTradingHours ? 'Yes' : 'No',
        },
        {
          field: 'addServiceLog',
          headerName: 'Add Service Log',
          flex: 0.5,
          type: 'singleSelect',
          valueOptions: ISACTIVE_FILTER_DROPDOWN_ITEM_ARRAY,
          valueGetter: (params: GridValueGetterParams) => params.row.addServiceLog ? 'Yes' : 'No',
        },
        {
          field: 'action',
          headerName: '',
          sortable: false,
          filterable: false,
          flex: 0.1,
          headerAlign: 'center',
          renderCell: (params: GridRenderCellParams) => (
            <Grid>
              {
                editPermission && <IconButton aria-label="edit" onClick={() => {
                  setEditConfigData(params.row);
                  setOpenModel(true);
                }}>
                  <Edit />
                </IconButton>
              }
            </Grid>
          )
        }
      ]);
    }
    else {
      setColumns([
        {
          field: 'id',
          headerName: 'ID',
          flex: 0.1,
          type: 'number',
          valueFormatter: (params: GridValueFormatterParams) => params.value.toString()
        },
        {
          field: 'name',
          headerName: 'Name',
          flex: 0.9,
          valueGetter: (params: GridValueGetterParams) => params.row.name,
        },
        {
          field: 'clientcontract',
          headerName: 'Client/Contract',
          flex: 1.2,
          valueGetter: (params: GridValueGetterParams) => params.row.clientContractHierarchy,
        },
        {
          field: 'shipmentType',
          headerName: 'Shipment Type',
          flex: 0.7,
          valueGetter: (params: GridValueGetterParams) => getDataGridListColumnNames(modelData?.shipmentTypes, params, 'shipmentType')
        },
        {
          field: 'courier',
          headerName: 'Courier',
          flex: 0.7,
          valueGetter: (params: GridValueGetterParams) => getDataGridListColumnNames(modelData?.couriers, params, 'courier')
        },
        {
          field: 'shipmentStatus',
          headerName: 'Shipment Status',
          flex: 0.7,
          valueGetter: (params: GridValueGetterParams) => getDataGridListColumnNames(modelData?.shipmentStatuses, params, 'shipmentStatus')
        },
        {
          field: 'event',
          headerName: 'Event',
          flex: 1.2,
          type: 'singleSelect',
          valueOptions : NOTIFICATION_EVENTS,
          valueGetter: (params: GridValueGetterParams) => getDataGridListColumnNames(NOTIFICATION_EVENTS, params, 'event') 
        },
        {
          field: 'messageType',
          headerName: 'Message Type',
          flex: 0.8,
          valueGetter: (params: GridValueGetterParams) => getNotificationListColumnLabel(NOTIFICATION_MESSAGE_TYPES, params.row.messageType),
        },
    
        {
          field: 'contactRecordType',
          headerName: 'Contact Record Type',
          flex: 1.5,
          type: 'singleSelect',
          valueOptions: NOTIFICATION_CONTACT_RECORD_TYPES,
          valueGetter: (params: GridValueGetterParams) => getNotificationListColumnLabel(NOTIFICATION_CONTACT_RECORD_TYPES, params.row.contactRecordType),
        },
        {
          field: 'template',
          headerName: 'Template',
          flex: 0.9,
          valueGetter: (params: GridValueGetterParams) => params.row.templateName,
        },
        {
          field: 'emailAddresses',
          headerName: 'Email Addresses',
          flex: 1.2,
          valueGetter: (params: GridValueGetterParams) => params.row.emailAddresses,
        },
        {
          field: 'phoneNumbers',
          headerName: 'Phone Numbers',
          flex: 1.2,
          valueGetter: (params: GridValueGetterParams) => params.row.phoneNumbers,
        },
        {
          field: 'considerTradingHours',
          headerName: 'Consider Traing Hours',
          flex: 0.8,
          type: 'singleSelect',
          valueOptions: ISACTIVE_FILTER_DROPDOWN_ITEM_ARRAY,
          valueGetter: (params: GridValueGetterParams) => params.row.considerTradingHours ? 'Yes' : 'No',
        },
        {
          field: 'addServiceLog',
          headerName: 'Add Service Log',
          flex: 0.8,
          type: 'singleSelect',
          valueOptions: ISACTIVE_FILTER_DROPDOWN_ITEM_ARRAY,
          valueGetter: (params: GridValueGetterParams) => params.row.addServiceLog ? 'Yes' : 'No',
        },
        {
          field: 'action',
          headerName: '',
          sortable: false,
          filterable: false,
          flex: 0.1,
          headerAlign: 'center',
          renderCell: (params: GridRenderCellParams) => (<Grid>
            <IconButton aria-label="edit" onClick={() => {
              setEditConfigData(params.row);
              setOpenModel(true);
            }}>
              <Edit />
            </IconButton>
          </Grid>)
        }
      ]);
    }
  }, [selectedNotificationType, notificationConfig]);

  return (
    <Grid container>
      <Grid item mb={2}>
        <SelectMenu
          id="noti-type"
          label="Type"
          required={true}
          items={notificationTypes}
          selectedValue={selectedNotificationType}
          onChange={changeType}
          optionalLabelEnabled={true}
        />
      </Grid>
      <GridContainer>
        <CustomDataGrid
          key={selectedNotificationType}
          columns={columns}
          rows={notificationConfigData || []}
          rowCount={rowCountState}
          exportFileName=""
          enableCSVExport={false}
          columnSelectionItems={selectedNotificationType === NotificationConfigType.Service ? serviceColumnSelectionItems : shipmentColumnSelectionItems}
          filterMode={GridFeatureModeConstant.server}
          onFilterModelChange={(filterModel: GridFilterModel) => {
            onFilterChange(filterModel);
          }}
          loading={selectedNotificationType !== '-1' && (isLoading || isRefetching)}
          rowsPerPageOptions={[25, 50, 100]}
          pagination={true}
          page={page}
          pageSize={pageSize}
          paginationMode={GridFeatureModeConstant.server}
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
          }}
          sortingMode={GridFeatureModeConstant.server}
          onSortModelChange={handleSortModelChange}
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionModelChange}
          componentsProps={{
            toolbar: {
              printOptions: { disableToolbarButton: false }
            }
          }}
          filterModel={filterModel}
          getRowHeight={() => 'auto'}
        />
      </GridContainer>
      <NotificationRulesConfigModelFragment open={openModel} closeModel={closeModel} modelData={modelData} data={editConfigData} />
    </Grid>
  );
};