import React, { useState,useEffect, useRef } from 'react';
import { Box, Grid, Stack, FormControl, FormHelperText } from '@mui/material';
import { Add } from '@mui/icons-material';
import SignatureCanvas from 'react-signature-canvas';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { RadioGroup } from '../../atoms/RadioGroup';
import { UploadButton } from '../../molecules/UploadButton';
import { CheckItem } from '../../molecules/CheckItem';
import { FormContainerItem, FormFieldItem, FormFieldType } from '../../../@types';
import { Folders } from '../../../@types/uploaded-files.type';
import { uploadFiles } from '../../../api/uploadedFiles';
import { FormGeneratorProps } from './FormGenerator.props';
import { 
  StyledTextField, 
  StyledSelectMenu, 
  StyledDatePicker,
  StyledGrid, 
  StyledPaper,
  SignatureCanvasWrapper,
  StyledStack
} from './FormGenerator.styles';
import { DecisionPromptFormFragment } from '../../../pages/Services/UpdateService/ServiceActionBarFragment/FormInterceptor/DecisionPromptFormFragment/DecisionPromptFormFragment';
import { DamagedPartSelectionKeys } from '../../../@types/part.type';
import { ButtonVariantProps, Button, ButtonColorProps } from '../../atoms/Button';
import { COLORS } from '../../../configs/colors';
import { RichTextEditor } from '../../atoms/RichTextEditor';
import { RICH_TEXT_EDITOR_EMPTY_TEXT } from '../../../constants/common';
import { SERVICE_ATTACHMENTS_MIME_TYPES } from '../../../configs/envs';
import { ServiceAttachmentMimeTypes } from '../../../configs/uploaded-files';
import { getMatchingMimeTypes } from '../../../utils/upload-file';
import { ServiceAttachmentUploadFeature } from '../../../configs/enums';
import { filterInteractionFormGeneration } from '../../../utils/interactions';

export const FormGenerator: React.FC<FormGeneratorProps> = ({
  subformTypes,
  value,
  onChange,
  partActionData,
  onDamagedPartSelect,
  service,
  onAddForm,
  isRepeatable,
  forms
}) => {
  const [containers, setContainers] = useState<FormContainerItem[]>([]);
  const [lastFormId, setLastFormId] = useState<number>(0);
  const signaturePadRef = useRef<any>(null);

  useEffect(() => {
    !containers.length && setContainers(value.containers);
  }, [value.containers]);

  useEffect(() => {
    value.containers.forEach((container) => 
      container.fields.forEach((field) => {
        if (field.type === FormFieldType.Signature) {
          if (Math.max(...forms.map((form) => form.id)) !== lastFormId && Math.max(...forms.map((form) => form.id)) !== value.id) {// For repeatable forms
            signaturePadRef.current.clear();
            signaturePadRef.current.fromDataURL(field.value);
            handleOnChange(field.value, container, field);
            setLastFormId(Math.max(...forms.map((form) => form.id)));
          } else return;
        }
        if (field.type === FormFieldType.Dropdown) {
          const defaultValue = field.options?.find((option) => option.isDefault)?.value;
          !field.value && defaultValue && handleOnChange(defaultValue, container, field);
        }
      })
    );
  }, [forms]);

  const handleOnChange = (val: any, container: FormContainerItem, field?: FormFieldItem) => {
    if (val?.isDecisionPrompt) {
      container.fields = !val?.questionData?.isYes ? containers.filter((element) => element.id === container.id )[0].fields : container.fields;
    }
    const isMerchantDamageData = [];
    const modifiedFields = container.fields.map((fieldObject) => {
      (fieldObject.id === field?.id && field.type === FormFieldType.MerchantDamageTemplate 
        && isMerchantDamageData.push(1) && onDamagedPartSelect && onDamagedPartSelect(DamagedPartSelectionKeys.FieldId, field.id));
      isMerchantDamageData.length && onDamagedPartSelect && onDamagedPartSelect(DamagedPartSelectionKeys.SerialData, val.serialData);
      if (isMerchantDamageData.length && val.photoKeys) {
        onDamagedPartSelect && onDamagedPartSelect(DamagedPartSelectionKeys.Photos, val.photoKeys);
      }
      return fieldObject.id === field?.id ? {
        ...field,
        value: val,
      } : fieldObject;
    });
    
    const modifiedContainers = value.containers.map((containerObject) => {
      (containerObject.id === container?.id && isMerchantDamageData.length 
        && onDamagedPartSelect && onDamagedPartSelect(DamagedPartSelectionKeys.ContainerId, container.id));
      return containerObject.id === container.id ? {
        ...container,
        fields: modifiedFields,
        questionData: val?.questionData || containerObject.questionData
      } : containerObject;
    });

    onChange({ ...value, containers: modifiedContainers });
  };

  const clearSignature = (container: FormContainerItem, field: FormFieldItem) => {
    const signaturePad = signaturePadRef.current;

    if (signaturePad) {
      signaturePad.clear();
      handleOnChange('', container, field);
    }
  };

  const saveSignature = (container: FormContainerItem, field: FormFieldItem) => {
    const signaturePad = signaturePadRef.current;

    if (signaturePad) {
      const signatureData = signaturePad.toDataURL();
      handleOnChange(signatureData, container, field);
    }
  };

  const addForm = () => {
    onAddForm && onAddForm(value.templateId || 0,value.title);
    value.containers.forEach((container) => 
      container.fields.forEach((field) => 
        field.type === FormFieldType.Signature && signaturePadRef.current.clear()
      )
    );
  };

  const getSubForm = (fieldType: FormFieldType, container: FormContainerItem, field: FormFieldItem) => {
    const subformType = subformTypes?.find((obj) => obj.formType === fieldType);
    if (subformType && filterInteractionFormGeneration(field)) {
      const SubFormReactNode = subformType.node;
      return (
        <SubFormReactNode
          key={`${container.id}-${field.id}`}
          service={subformType.service}
          preSelectedData={field.preSelectedData}
          isError={field.isError}
          value={field.value}
          onChange={(val) => {
            // TODO: Remove this log after interactions completed.
            // console.log('FormGenerator logs', val);
            handleOnChange(val, container, field);
          }}
          partActionData={partActionData}
        />
      );
    }
    return null;
  };

  return (
    <>
      <Grid container spacing={2} mb="10px">
        {value.containers?.map((container: FormContainerItem) => {
          return (
            <StyledPaper key={container.id} containerData={container}>
              {container.isDecisionPrompt &&
                <DecisionPromptFormFragment value={container} onChange={(val) => handleOnChange(val, container)}/>
              }
              {(!container.isDecisionPrompt || (container.isDecisionPrompt && container.questionData?.isYes)) &&
              container.fields?.map((field: FormFieldItem) => (
                <StyledGrid key={field.id} item xs={12} fieldType={field.type}>
                  {field.type === FormFieldType.Label &&
                    <Typography
                      variant={TypographyVariantProps.Body1}
                      fontWeight={TypographyFontWeightProps.Bold}
                    >
                      {field.title}
                    </Typography>
                  }
                  {field.type === FormFieldType.Text &&
                    <StyledTextField
                      label={field.title}
                      value={field.value || ''}
                      onChange={(val) => handleOnChange(val, container, field)}
                      error={field.isError && !field.value}
                    />
                  }
                  {field.type === FormFieldType.RichTextbox &&
                  <>
                    <Typography variant={TypographyVariantProps.Body1}>{field.title}</Typography>
                    <FormControl
                      error={field.isError && (field.value === '' || !field.value)}
                    >
                      <RichTextEditor
                        value={field.value}
                        onValueChange={(textValue: string) => {
                          if (textValue === RICH_TEXT_EDITOR_EMPTY_TEXT) {
                            textValue = '';
                          }
                          handleOnChange(textValue, container, field);
                        }}
                        error={field.isError && (field.value === '' || !field.value)}
                      />
                      {field.isError && (field.value === '' || !field.value) && (
                        <FormHelperText>*Required</FormHelperText>
                      )}
                    </FormControl>
                  </>
                  }
                  {field.type === FormFieldType.Dropdown &&
                    <StyledSelectMenu
                      id={`form-generator-select-menu-${field.id}`}
                      label={field.title}
                      items={field.options?.map((obj) => ({
                        value: obj.value,
                        label: obj.title,
                        disabled: !obj.isActive
                      })) || []}
                      selectedValue={field.value || ''}
                      onChange={(val) => handleOnChange(val, container, field)}
                      validate={field.isError && !field.value}
                    />
                  }
                  {field.type === FormFieldType.Radio &&
                    <StyledStack>
                      <RadioGroup
                        id={`${field.id}`}
                        title={field.title}
                        options={field.options || []}
                        value={field.value || ''}
                        onChange={(val) => handleOnChange(val, container, field)}
                        error={field.isError && !field.value}
                      />
                      {field.isError && !field.value && <Typography textColor={COLORS.DarkRed} variant={TypographyVariantProps.Body2}>*Required</Typography>}
                    </StyledStack>
                  }
                  {field.type === FormFieldType.Checkbox &&
                    <StyledStack>
                      <CheckItem
                        label={field.title}
                        value={field.value || false}
                        onChange={(val) => handleOnChange(val, container, field)}
                      />
                      {field.isError && !field.value && <Typography textColor={COLORS.DarkRed} variant={TypographyVariantProps.Body2}>*Required</Typography>}
                    </StyledStack>
                  }
                  {field.type === FormFieldType.Date &&
                    <StyledStack>
                      <StyledDatePicker
                        label={field.title}
                        value={field.value || ''}
                        onChange={(val) => handleOnChange(val, container, field)}
                        normalSize
                      />
                      {field.isError && !field.value && <Typography textColor={COLORS.DarkRed} variant={TypographyVariantProps.Body2}>*Required</Typography>}
                    </StyledStack>
                  }
                  {field.type === FormFieldType.File &&
                  <StyledStack>
                    <UploadButton
                      showName
                      label={field.title}
                      maxFileSize={1024}
                      fileTypes={getMatchingMimeTypes(SERVICE_ATTACHMENTS_MIME_TYPES, ServiceAttachmentMimeTypes.InteractionFileUpload)}
                      value={field.value}
                      setFileDetails={async (fileList: FileList) => {
                        if (fileList.length > 0) {
                          const formData = new FormData();
                          const files = [...fileList];
                          files.forEach((item) => formData.append('fileList', item));
                          const result = await uploadFiles(service.id, formData, Folders.ServiceAttachments, ServiceAttachmentUploadFeature.InteractionFileUpload);
                          handleOnChange(`${result['0'].fileName} ${result['0'].dbSuccess ? 'successfully uploaded' : 'upload failed'}`, container, field);
                        }
                      }}
                    />
                    {field.isError && !field.value && <Typography textColor={COLORS.DarkRed} variant={TypographyVariantProps.Body2}>*Required</Typography>}
                  </StyledStack>
                  }
                  {field.type === FormFieldType.Photo &&
                  <StyledStack>
                    <UploadButton
                      showName
                      label={field.title}
                      maxFileSize={1024}
                      fileTypes={getMatchingMimeTypes(SERVICE_ATTACHMENTS_MIME_TYPES, ServiceAttachmentMimeTypes.InteractionPhotoUpload)}
                      value={field.value}
                      setFileDetails={async (fileList: FileList) => {
                        if (fileList.length > 0) {
                          const formData = new FormData();
                          const files = [...fileList];
                          files.forEach((item) => formData.append('fileList', item));
                          const result = await uploadFiles(service.id, formData, Folders.ServiceAttachments, ServiceAttachmentUploadFeature.InteractionPhotoUpload);
                          handleOnChange(`${result['0'].fileName} ${result['0'].dbSuccess ? 'successfully uploaded' : 'upload failed'}`, container, field);
                        }
                      }}
                    />
                    {field.isError && !field.value && <Typography textColor={COLORS.DarkRed} variant={TypographyVariantProps.Body2}>*Required</Typography>}
                  </StyledStack>
                  }
                  {field.type === FormFieldType.Signature &&
                  <StyledStack>
                    <Stack>
                      <Typography variant={TypographyVariantProps.Caption} textColor="#A9A9A9">
                        {'Sign Here'}
                      </Typography>
                      <SignatureCanvasWrapper>
                        <SignatureCanvas ref={signaturePadRef} canvasProps={{ className: 'sigCanvas', width: 400, height: 300 }} onEnd={() => saveSignature(container, field)} />
                      </SignatureCanvasWrapper>
                      <Box>
                        <Button variant={ButtonVariantProps.Secondary} onClick={() => clearSignature(container, field)}> Clear Signature </Button>
                      </Box>
                    </Stack>
                    {field.isError && !field.value && <Typography textColor={COLORS.DarkRed} variant={TypographyVariantProps.Body2}>*Required</Typography>}
                  </StyledStack>
                  }
                  {getSubForm(field.type, container, field)}
                </StyledGrid>
              ))}
            </StyledPaper>
          );
        })}
      </Grid>
      {isRepeatable &&
        <Grid mb={2} alignSelf="flex-start">
          <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Info} startIcon={<Add />} onClick={() => addForm()}>
            Re-use / Duplicate
          </Button>
        </Grid>
      }
    </>
  );
};