import moment from 'moment';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import Papa from 'papaparse';
import { GridFilterModel, GridFilterItem } from '@mui/x-data-grid-pro';
import { EMPTY_FIELD_MESSAGE, TAB } from '../constants/common';
import { RoutesEnum, ServiceGeoLocationPosition } from '../@types';

export const caseIndependentCompare = (stringA: string, stringB: string) => {
  return stringA.toLocaleUpperCase().includes(stringB.toLocaleUpperCase());
};

export const isString = (value: any): value is string => {
  return typeof value === 'string';
};

export const isEmptyString = (value: any) => {
  return value?.toString().trim().length === 0;
};

export const isValideStringLength = (value: string, length: number) => {
  const trimmedValue = value.trim();
  return trimmedValue && (trimmedValue.length > 0 && trimmedValue.length < length);
};

export const isTextContainValidCharacters = (value: string, regExp?: RegExp) => {
  const result = regExp != null ? regExp.test(value) : /^[a-z0-9 ,.'-]+$/i.test(value);
  return !result;
};

export const validateText = (value: string, text: string, regExp?: RegExp) => {
  let errorText = '';
  if (isEmptyString(value)) {
    errorText = `${text} cannot be empty`;
  } else if (isTextContainValidCharacters(value, regExp)) {
    errorText = `Invalid character or characters included in ${text}`;
  }
  return errorText;
};

export const formatDate = (date: string, defaultValue?: string, displayAsUTC?: boolean) => {
  if (!date) {
    return defaultValue != undefined ? defaultValue : '-';
  }
  if (displayAsUTC) {
    return moment.utc(date).format('YYYY-MM-DD HH:mm');
  }
  return moment(date).format('YYYY-MM-DD HH:mm');
};

export const formatServiceLogDate = (date: string) => {
  if (!date) {
    return '-';
  }
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
};

export const formatDateSimple = (date: string) => {
  if (!date) {
    return '-';
  }
  return moment(date).format('YYYY-MM-DD HH:mm');
};

export const formatDateOnly = (date: string) => {
  if (!date) {
    return '-';
  }
  return moment(date).format('YYYY-MM-DD');
};

export const isDropDownEmpty = (value: string) => {
  // ToDo: value === '0' will remove after whole implementation is done
  if (value === '' || value === '-1' || value === '0') {
    return true;
  } else {
    return false;
  }
};

export const dropdownVerifiedMessage = (value: string, fieldName: string) => {
  return isDropDownEmpty(value) ? `${fieldName} ${EMPTY_FIELD_MESSAGE}` : '';
};

export const getFormattedDate = (date: string) => {
  if (isEmptyString(date))
    return undefined;
  else
    return dayjs(date, 'YYYY-MM-DD HH:mm').format();
};

export const getCurrentTimestamp = () => {
  return new Date().toISOString();
};

export const getCurrentTime = () => {
  return dayjs(new Date()).format('YYYY-MM-DD HH:mm');
};

export const getTimestamp = (date: string) => {
  return dayjs(date, 'YYYY-MM-DD HH:mm').toISOString();
};

export const getJSTimestamp = (date: string) => {
  return new Date(date).toISOString();
};

export const getDateFromTimestamp = (date: string) => {
  if (!date) {
    return '-';
  }
  return dayjs(date).format('YYYY-MM-DD HH:mm');
};

export const emptyFieldValidation = (value: string, text: string) => {
  if (isEmptyString(value)) {
    return text + EMPTY_FIELD_MESSAGE;
  } else {
    return '';
  }
};

export const emptyDropdownValidation = (value: string, text: string) => {
  if (isDropDownEmpty(value)) {
    return text + EMPTY_FIELD_MESSAGE;
  } else {
    return '';
  }
};


export const formatDateTime = (date: string | undefined) => {
  if (!date) {
    return '-';
  }
  return moment(date).format('YYYY-MM-DD hh:mm:ss');
};

export const formatShortDate = (date: string | undefined) => {
  if (!date) {
    return '-';
  }
  return moment(date).format('YYYY-MM-DD');
};

export const calculateDateTimeDuration = (date: string) => {
  const loggedDateTime = new Date(date);
  const currentDateTime = new Date();

  // Calculate the time difference in milliseconds
  const timeDiff = currentDateTime.getTime() - loggedDateTime.getTime();

  const seconds = moment.duration(timeDiff).seconds();
  const minutes = moment.duration(timeDiff).minutes();
  const hours = moment.duration(timeDiff).hours();
  const weeks = moment.duration(timeDiff).weeks();
  const days = moment.duration(timeDiff).days() - (weeks * 7);
  const months = moment.duration(timeDiff).months();
  const years = moment.duration(timeDiff).years();

  if (years === 0 && months === 0 && weeks === 0 && days === 0 && hours === 0 && minutes === 0)
    return `+${seconds}s`;
  else if (years === 0 && months === 0 && weeks === 0 && days === 0 && hours === 0)
    return `+${minutes}m`;
  else if (years === 0 && months === 0 && weeks === 0 && days === 0)
    return `+${hours}h ${minutes}m`;
  else if (years === 0 && months === 0 && weeks === 0)
    return `+${days}d ${hours}h ${minutes}m`;
  else if (years === 0 && months === 0)
    return `+${weeks}w ${days}d ${hours}h ${minutes}m`;
  else if (years === 0)
    return `+${months}m ${weeks}w ${days}d ${hours}h ${minutes}m`;
  else
    return `+${years}y ${months}m ${weeks}w ${days}d ${hours}h ${minutes}m`;
};

export const sanitizeElement = (text: string) => text && text !== '' ? DOMPurify.sanitize(text) : '';

export const nameValidation = (value: string, text: string) => {
  if (isEmptyString(value)) {
    return text + EMPTY_FIELD_MESSAGE;
  } else {
    return '';
  }
};

export const stripHtmlTags = (html: string) => {
  const stripDiv = document.createElement('div');
  stripDiv.innerHTML = sanitizeElement(html);
  return stripDiv.innerText ? stripDiv.innerText : '';
};

export const parseExcelData = (excelDataString: string) => {
  const parsedData = Papa.parse(excelDataString,
    {
      header: false,
      skipEmptyLines: true,
      delimiter: TAB,
    }
  );
  return parsedData?.data ? parsedData.data : [];
};

export const capitalizeFirstLetter = (input: string) => {
  return input && input !== '' ? input.charAt(0).toUpperCase() + input.slice(1).toLowerCase() : '';
};

export const generateUniqueCode = () => {
  const randomNum = Math.floor(Math.random() * 1000000);
  const base64Encoded = btoa(randomNum.toString());
  return base64Encoded;
};

export const getServiceGeoLocationData = (serviceId: number): ServiceGeoLocationPosition | null => {
  const geoLocationData = localStorage.getItem(RoutesEnum.GeoLocationPositionKey);
  if (geoLocationData) {
    const geoLocationDataArray: ServiceGeoLocationPosition[] = JSON.parse(geoLocationData);
    const serviceGeoLocationData = geoLocationDataArray.find((locationData: ServiceGeoLocationPosition) =>
      locationData.serviceId === serviceId
    );
    if (serviceGeoLocationData) {
      return serviceGeoLocationData;
    }
  }
  return null;
};

export const saveServiceGeoLocationData = (serviceGeoLocationData: ServiceGeoLocationPosition) => {
  const geoLocationData = localStorage.getItem(RoutesEnum.GeoLocationPositionKey);
  if (geoLocationData) {
    const geoLocationDataArray: ServiceGeoLocationPosition[] = JSON.parse(geoLocationData);
    const existingSeviceGeoLocationDataIndex = geoLocationDataArray.findIndex((locationData) => {
      return locationData.serviceId === serviceGeoLocationData.serviceId;
    });
    if (existingSeviceGeoLocationDataIndex !== -1) {
      geoLocationDataArray.splice(existingSeviceGeoLocationDataIndex, 1);
    }
    geoLocationDataArray.push(serviceGeoLocationData);
    localStorage.setItem(RoutesEnum.GeoLocationPositionKey, JSON.stringify(geoLocationDataArray));
    return;
  }
  localStorage.setItem(RoutesEnum.GeoLocationPositionKey, JSON.stringify([serviceGeoLocationData]));
  return;
};

export const convertHyphenTextToReadText = (text: string): string => {
  if (text && text !== '') {
    const words = text.split('-');
    const titleCasedString = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
    return titleCasedString;
  }
  return '';
};

export const convertToArray = (arrayData: string) => {
  if (arrayData) {
    return arrayData.split(',');
  }
  return [];
};

export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const checkListPagetFilterAvailability = (filters: GridFilterModel) => {
  return filters.items.every((item: GridFilterItem) => item.operatorValue === 'isEmpty' || item.operatorValue === 'isNotEmpty' || !!item.value ||
  (item.columnField === 'reviewed' && item.value !== undefined) || (item.columnField === 'clientInvoiced' && item.value !== undefined));
};