import { styled } from '@mui/material/styles';
import { Typography } from '../../../components/atoms/Typography';

export const StyledWrapper = styled('div')`
  width: 100%;
`;

export const SmallPartLabel = styled(Typography)`
  padding: 1px;
  font-style: italic;
  font-size: x-small;
`;

export const GridContainer = styled('div')`
  height: 92vh;
  width: 100%;
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-viewport,
  .MuiDataGrid-row {
    max-height: fit-content !important;
  }
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-cell {
    overflow: auto;
    white-space: initial !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .MuiDataGrid-toolbarContainer {
    display: none !important;
  }
`;