import { AddressDataType } from '../components/organisms/StructureAddress';
import { LabelValue } from './common.type';
import { PartConditionOptions, SfhObject } from './part.type';

export interface AddStockDetailHelperText {
  sourceHelperText: string;
  destinationHelperText: string;
  clientContractHelperText: string;
}

export enum AddStockDetailFields {
  SourceField = 'Source field',
  DestinationField = 'Destination field',
  ClientContractField = 'Client/Contract Selection field'
}

export enum ShipmentStatusCodes {
  Pending = 'pending',
  Partial = 'partial',
  Complete = 'complete',
  Cancelled = 'cancelled',
  InTransit = 'in-transit',
  Received = 'received',
}

export interface Shipment {
  id: number;
  source: string;
  destination: string;
  shipmentStatusName?: string;
  shipmentStatusCode: string;
  customerId: string;
  connoteNo: string;
  shippingCost?: number;
  shipmentTypeName?: string;
  shipmentTypeCode: string;
  sourceNodeId: number;
  destinationNodeId: number;
  expectedArrivalDate: string;
  shippingProviderId?: string;
  note?: string;
  referenceNumber1?: string;
  referenceNumber2?: string;
  referenceNumber3?: string;
  clientHierarchy: string;
  clientHierarchySearch?: string;
  clientHierarchyName: string;
  client: string;
  contract: Array<string>;
  serviceId?: number;
  bypassReceipt?: boolean;
  overrideConsumption?: boolean;
  manifest?: SavedManifestItem[];
}

export interface ShipmentStatus {
  code: string;
  name: string;
}

export interface ShipmentListResponse {
  previousPage?: number;
  currentPage?: number;
  nextPage?: number;
  total: number;
  limit?: number;
  data?: [Shipment];
}

export interface DispatchDetail {
  id?: number;
  source: string;
  sourceNodeId: number;
  destination: string;
  destinationNodeId: number;
  shipmentStatusCode: string;
  clientHierarchy: string;
  clientHierarchySearch?: string;
  clientHierarchyName?: string;
  client: string;
  contract: string[];
  serviceId?: number;
  bypassReceipt?: boolean;
  overrideConsumption?: boolean;
  connoteNo: string;
  note?: string;
  shipmentTypeCode: string;
  shippingCost: number;
  shippingProviderName?: string;
}

export interface DispatchDetailHelperText {
  sourceHelperText: string;
  destinationHelperText: string;
}

export interface ManifestSerialItem {
  id?: number;
  serial1: string;
  serial2: string;
  serial3: string;
}

export interface ManifestSerialObject {
  id: number;
  rowId?: number;
  softwareVersion?: string;
  firmwareVersion?: string;
  hardwareRevision?: string;
  fleetTag?: string;
  condition: string;
  smartInput?: string;
  serialsDataSet?: Array<ManifestSerialItem>;
  selectedSerials?: Array<number>;
  filteredSerials?: Array<ManifestSerialItem>;
}

export interface IncomingShipmentType {
  shipmentId: number;
  sentDate: string;
  connote?: string;
  lastTrackingEvent?: string;
  totalQty: number;
}

export interface BatchReceive {
  id: number;
  createdBy: string;
  createdAt: string;
  destinationNodeId: number;
  destinationNodeName: string;
  qty: number;
}

export interface BatchReceiveListResponse {
  previousPage?: number;
  currentPage?: number;
  nextPage?: number;
  total: number;
  limit?: number;
  data?: [BatchReceive];
}

export interface ReturnSuggestion {
  id: number;
  partId?: number;
  ns_partId?: number;
  partTypeName: string;
  partTypeNumber: string;
  partTypeId: number;
  serial?: string;
  quantityInStock?: number;
  conditionName: string;
  conditionCode: string;
  nodeName: string;
  softwareVersionId?: number;
  firmwareVersionId?: number;
  hardwareRevisionId?: number;
  fleetTagId?: number;
  softwareVersion?: string;
  firmwareVersion?: string;
  hardwareVersion?: string;
  fleetTagName?: string;
  updatedAt: string;
}

export interface ReturnManifest {
  source?: string;
  sourceNodeId?: number;
  destination?: string;
  destinationNodeId?: number;
  note?: string;
  shipmentTypeCode?: string;
  shipmentStatusCode?: string;
  smartInput?: string;
  serialisedManifest: Array<ReturnSuggestion>;
  nonSerialisedManifest: Array<ReturnSuggestion>;
  serialisedManifestManual: Array<ReturnSuggestion>;
  nonSerialisedManifestManual: Array<ReturnSuggestion>;
}

export interface SerialsType {
  serial1: string;
  serial2: string;
  serial3: string;
}

export interface ManifestValueType {
  shipmentId?: string;
  conditionCode?: string;
  fleetTags?: string;
  partTypeId: string;
  softwareVersion?: string;
  firmwareVersion?: string;
  hardwareRevision?: string;
  serials?: SerialsType[];
}

export enum ShipmentTypeCodes {
  AddStock = 'add-stock'
}

export interface SerialPopupObject {
  id: number;
  partTypeId: number;
  sfhOptions: SfhObject;
  fleetTagOptions: LabelValue[];
  conditionOptions: LabelValue[];
}

export interface ShipmentType {
  code: string;
  name: string;
}

export interface CourierData {
  id: number;
  name: string;
}

export interface FreightPackageTypeResponse {
  id: number;
  name: string;
  height: number;
  width: number;
  length: number;
}

export interface DestinationAddressAttribute {
  id: number;
  propertyTypeCode: string;
  value: AddressDataType;
}

export interface FreightOrderContact {
  name: string;
  email?: string;
  phone: string;
  building?: string;
  company?: string;
  street: string;
  suburb: string;
  city?: string;
  state?: string;
  post_code?: string;
  country: string;
  delivery_instructions?: string;
}

export interface CreateFreightOrderType {
  shipmentId: number;
  reference: string;
  shipping_method: string;
  signature_required: boolean;
  dangerous_goods: boolean;
  sender_details?: FreightOrderContact;
  destination: FreightOrderContact;
}

export interface ArchiveFreightOrderType {
  id: number;
  shipmentId: number;
  freightIntegrator: string;
  orderNumber: string;
  orderId: number;
  isReturnOrder: boolean;
}

export interface ReturnFreightOrderType {
  shipmentId: number;
  freightIntegrator: string;
  orderNumber: string;
  orderId: number;
}

export interface DeliveryServicesPackage {
  weight: number;
  height: number;
  width: number;
  length: number;
}

export interface QuoteFreightOrderType {
  street: string;
  suburb?: string;
  city?: string;
  state?: string;
  post_code: string;
  country_code: string;
  packages: DeliveryServicesPackage[];
  return_order?: boolean;
}

export interface QuoteFreightOrderTypeResponse {
  carrier: string;
  carrier_name?: string;
  service_name?: string;
  service_code?: string;
  total_price: number;
}

export interface FreightOrderByShipment {
  id: number;
  shipmentId: number;
  freightIntegrator: string;
  orderNumber: string;
  orderId: string;
  isReturnOrder: boolean;
  showArchived: boolean;
}
export interface ManifestData {
  partId?: number;
  partTypeId?: number;
  conditionCode?: string;
  fleetTags?: string;
  fleetTagId?: number;
  softwareVersion?: number;
  hardwareRevision?: number;
  firmwareVersion?: number;
  qty?: number;
  selected: boolean;
  locationId?: number;
}

export interface SavedManifestItem {
  id?: number;
  partTypeName: string;
  partTypeNo: string;
  serial?: string;
  conditionFleetTag?: string;
  quantity?: number;
}

export interface NonSerializedShipmentManifestData {
  shipmentId: number;
  partTypeId: number;
  qty: number;
  maxQty: number;
  conditionCode: PartConditionOptions;
  fleetTags: string;
  putAwayLocationName: string;
  putAwayLocationId: number;
  selected?: boolean;
  partTypeName?: string;
  partTypeNumber?: string;
  conditionName: string;
  fleetTagName?: string;
}

export interface SerialisedSuggestions {
  id: number;
  name: string;
  locationHierarchyText: string;
}

export interface NonSerialisedSuggestions {
  id: number;
  name: string;
  locationHierarchyText: string;
}

export interface ShipmentBatchReceiveData {
  partTypeId: number;
  partTypeName: string;
  isPartTypePopup: boolean;
  putAwayLocationId: number;
  putAwayLocationName: string;
  isPartTypeLiveSearch: boolean;
  isOther: boolean;
  smartInput?: string;
  nonSerialOptions: NonSerializedShipmentManifestData[];
  nonSerializedShipmentManifestItem: NonSerializedShipmentManifestData[];
  serializedShipmentManifestItem: SerializedShipmentManifestData[];
  isSerialsNotEmpty?: boolean;
  isNonSerialsNotEmpty?: boolean;
}

export interface SerializedShipmentManifestData {
  serial1: string;
  shipmentId: number;
  partId: number;
  conditionCode: PartConditionOptions;
  conditionName: string;
  putAwayLocationName: string;
  putAwayLocationId: number;
  shipmentReceiptId?: number;
  selected?: boolean;
  source?: ShipmentTypeCodes;
  partTypeId?: number;
  partTypeName?: string;
  partTypeNumber?: string;
  softwareVersion?: string;
  firmwareVersion?: string;
  hardwareVersion?: string;
  fleetTagName?: string;
  serialisedSuggestions: SerialisedSuggestions[];
  isReturn?: boolean;
  manifestId: number;
}

export interface IndividualShipmentReceiveData {
  nonSerializedShipmentManifestItem: NonSerializedReceiveShipmentManifestData[];
  selectedNonSerializedShipmentManifestItem: NonSerializedReceiveShipmentManifestData[];
  serializedShipmentManifestItem: SerialisedReceiveShipmentManifestData[];
  selectedSerializedShipmentManifestItem: SerialisedReceiveShipmentManifestData[];
  isBulkPutAway?: boolean;
}

export interface SerialisedReceiveShipmentManifestData {
  id: number;
  serial1: string;
  shipmentId: number;
  partId: number;
  conditionCode: PartConditionOptions;
  conditionName: string;
  putAwayLocationName: string;
  putAwayLocationId: number;
  shipmentReceiptId?: number;
  selected?: boolean;
  source?: ShipmentTypeCodes;
  partTypeId?: number;
  partTypeName?: string;
  partTypeNumber?: string;
  softwareVersion?: string;
  firmwareVersion?: string;
  hardwareVersion?: string;
  fleetTagName?: string;
  serialisedSuggestions: SerialisedSuggestions[];
  isReturn?: boolean;
  manifestId: number;
}

export interface NonSerializedReceiveShipmentManifestData {
  id: number;
  shipmentId: number;
  partTypeId: number;
  qty: number;
  maxQty: number;
  conditionCode: PartConditionOptions;
  fleetTags: string;
  putAwayLocationName: string;
  putAwayLocationId: number;
  selected?: boolean;
  partTypeName?: string;
  partTypeNumber?: string;
  conditionName: string;
  fleetTagName?: string;
}

export interface NonSerializedShipmentManifestData {
  shipmentId: number;
  partTypeId: number;
  qty: number;
  maxQty: number;
  conditionCode: PartConditionOptions;
  fleetTags: string;
  putAwayLocationName: string;
  putAwayLocationId: number;
  selected?: boolean;
  nonSerialisedSuggestions: NonSerialisedSuggestions[];
}

export interface ShipmentBatchReceiveData {
  partTypeId: number;
  partTypeName: string;
  isPartTypePopup: boolean;
  putAwayLocationId: number;
  putAwayLocationName: string;
  isPartTypeLiveSearch: boolean;
  isOther: boolean;
  nonSerialOptions: NonSerializedShipmentManifestData[];
  nonSerializedShipmentManifestItem: NonSerializedShipmentManifestData[];
  serializedShipmentManifestItem: SerializedShipmentManifestData[];
}

export interface ShipmentServiceAllocationData {
  shipmentId: number;
  serviceId: number;
}

export interface ShipmentServiceAllocationList {
  id: number;
  shipmentId: number;
  createdBy: string;
  createdAt: string;
  source: string;
  destination: string;
}

export interface DispatchPrefillData {
  serviceId: number;
  locationId: number;
  locationName: string;
  subLocationNodeId?: number;
  primaryAllocationNodeId?: number;
  secondaryAllocationNodeId?: number;
  isFromServiceEdit?: boolean;
}

export interface ShipmentServiceAllocations {
  id: number;
  shipmentId: number;
  serviceId: number;
}

export interface ShipmentManifestSuppliers {
  id: number;
  supplierName: string;
  pricePerPackage: number;
  supplierPartTypeName: string;
  supplierPartTypeNumber: string;
  lastUpdated: string;
  itemPrice: number;
}

export enum ShipmentRecieveType {
  Individual = 'individual',
  Bulk = 'bulk',
}

export interface RecieveShipmentSearchNodes {
  id: number;
  recieveType: ShipmentRecieveType;
  name: string;
}

export interface ShipmentManifestSummery {
  shipmentId: number;
  destinationNodeId: number;
  destinationNode: string;
  serial: string;
  receiveEntireShipment: boolean;
  partManifestId: number;
  shipmentNote?: string;
  siblingNodeName: string;
  manifestSummery: { partTypeId: number; partTypeName: string; qty: number }[];
  nonSerializedShipmentManifestItem: NonSerializedShipmentManifestData[];
  serializedShipmentManifestItem: SerializedShipmentManifestData[];
}


export interface ShipmentByPartData {
  id: number;
  shipmentStatusCode: string;
  destinationNodeId: number;
  sourceNodeId: number;
  note?: string;
  serviceId?: number;
  connoteNo?: string;
  expectedArrivalDate?: string;
  completedAt?: string;
  createdAt?: string;
  sourceLocation: string;
  destinationLocation: string;
}