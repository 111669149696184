import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, AlertTitle, Box, Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { TextField } from '../../../components/atoms/TextField';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../../components/atoms/Typography';
import { RoleTypography } from '../../Roles/AddRole/AddRole.styles';
import { DropdownItem, SelectMenu } from '../../../components/atoms/SelectMenu';
import { FormControlLabel } from '../../../components/atoms/FormControlLabel';
import { Switch } from '../../../components/atoms/Switch';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { ActionPermissions, ColorProps, CustomPermission, LocationNodeData, NodeType, Tag } from '../../../@types';
import { LengthValidation, isInputFieldEmpty } from '../../../utils/services';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { Tags, getNewTagsMapped, getSelectedExistingTagsMapped, mapSavedTags } from '../../../components/organisms/Tags';
import { getAllTags, useAddTags } from '../../../queries/tags-query';
import { EntityType, StructureTypeCategory, SerialisedType, ContentDispositionType } from '../../../configs/enums';
import { isDropDownEmpty } from '../../../utils/common';
import { LiveSearchBox, LiveSearchListItem } from '../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../components/molecules/LocationSearchItem';
import { useSearchManufacturerNodes } from '../../../queries/live-search-query';
import { EditLiveSearchField } from '../../../components/molecules/EditLiveSearchField/EditLiveSearchField';
import { useGetPartTypeCategories } from '../../../queries/part-type-category-query';
import { useCreateChildPartType, useCreatePartType, useCreateRepairPricing, useCreateSFHware, useCreateSalePricing, useCreateSupplier, useCreateWarranty, useDeleteChildPartType, useGetAllConfigurationHistory, useGetOnePartType, useGetOnePartTypeOnClose, useUpdateChildPartType, useUpdatePartType, useUpdateRepairPricing, useUpdateSFHware, useUpdateSalePricing, useUpdateSupplier, useUpdateWarranty } from '../../../queries/part-type-query';
import { ConfigurationHistoryList, SFHwareTypeEnum, SalePricingObject, SalesRepairSupplierData, SoftwareFirmwareHardwareData, WarrantyData, PendingTransfersList, SFHwareHeaderEnum, PartType, PartTypeValidator, PartTypeParentSelector, BillOfMaterialType } from '../../../@types/partType.type';
import { CONFIGURATION_CHANGE_NOTES_LENGTH, MANUFACTURER_HELPER_TEXT, NAME_HELPER_TEXT, SAMPLE_PART_TYPE, SAMPLE_PART_TYPE_PARENT_SELECTOR, SAMPLE_PART_TYPE_VALIDATOR, SAMPLE_UPLOADED_FILE_ACTION_DATA, SFHWARE_VERSION_LENGTH } from '../../../constants/partType';
import { SelectionButton } from '../../../components/molecules/SelectionButton';
import { ContractMultiSelector } from '../../../components/molecules/ContractMultiSelector';
import { useGetNodeChildrenByNodeType, useGetStructuresByTypeCategoryCodeAndTypeCode } from '../../../queries/structure-query';
import { getClientHierarchy, leafNodeCreater } from '../../../utils/clientContractHierarchy';
import { useGetUploadedFilePresignedUrlById, useGetUploadedFiles } from '../../../queries/uploadedfiles-query';
import { FileTypes, FileUploadActionType, Folders, UploadedFile, UploadedFileActionData } from '../../../@types/uploaded-files.type';
import { Attachments } from '../../../components/organisms/Attachments';
import { uploadFiles, deleteUploadedFile } from '../../../api/uploadedFiles';
import { MaxFileSize } from '../../../configs/uploaded-files';
import { WarrantyPanel } from '../../../components/organisms/WarrantyPanel';
import { SoftwareFirmwareHardwarePanel } from '../../../components/templates/SoftwareFirmwareHardwarePanel';
import { ConfigurationHistory } from '../../../components/molecules/ConfigurationHistory';
import { MultipleImageUploadFragment } from './MultipleImageUploadFragment';
import { SalesRepairSupplierPanel } from '../../../components/templates/SalesRepairSupplierPricingPanel';
import { BillOfMaterials } from '../../../components/organisms/BillOfMaterials';
import { PartTypePendingTransfers } from '../../../components/molecules/PartTypePendingTransfers';
import { useGetPartTypeShipmentDataByPartTypeIdAndShipmentStatus } from '../../../queries/part-type-query';
import { ShipmentStatusCodes } from '../../../@types/shipment.type';
import { PrintLabelEntityTypes } from '../../../@types/print-label.type';
import { PrintLabelWrapper } from '../PartTypes.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { Modal } from '../../../components/atoms/Modal';
import { COLORS } from '../../../configs/colors';
import { PART_TYPES_ATTACHMENTS_MIME_TYPES } from '../../../configs/envs';
import { isUserHasPermission } from '../../../configs/permissions';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { StyledImage } from '../../../components/organisms/MultipleImageUpload/MultipleImageUpload.styles';
import { characterLengthValidation, clientContractValidator, compareWithPrevious, manufactuterValidator, timeout } from '../../../utils/part-type';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { NotFoundError } from '../../Error/NotFound';
import { ANY_ITEM_ARRAY } from '../../../constants/common';

export const AddEditPartType: React.FC = () => {
  const { id } = useParams();
  const partTypeId = id ? parseInt(id) : -1;
  const navigate = useNavigate();
  const isNew = id === 'create';

  const [partType, setPartType] = useState<PartType>(SAMPLE_PART_TYPE);
  const [previousPartType, setPreviousPartType] = useState<PartType>(SAMPLE_PART_TYPE);
  const [partTypeValidator, setPartTypeValidator] = useState<PartTypeValidator>(SAMPLE_PART_TYPE_VALIDATOR);
  const [parentSelector, setParentSelector] = useState<PartTypeParentSelector>(SAMPLE_PART_TYPE_PARENT_SELECTOR);
  const [configurationHistoryList, setConfigurationHistoryList] = useState<ConfigurationHistoryList[]>([]);
  const [partTypeCategoryList, setPartTypeCategoryList] = useState<DropdownItem[]>([]);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [selectedFileActionData, setSelectedFileActionData] = useState<UploadedFileActionData>(SAMPLE_UPLOADED_FILE_ACTION_DATA);
  const [pendingTransfersList, setPendingTransfersList] = useState<PendingTransfersList[]>([]);
  const [fullImage, setFullImage] = useState('');
  const [permissions, setPermissions] = useState<CustomPermission[]>();

  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const serviceTagsQuery = getAllTags(EntityType.TYPE_PARTTYPES);
  const searchNodeQuery = useSearchManufacturerNodes();
  const getAllPartTypeCategories = useGetPartTypeCategories();
  const createPartType = useCreatePartType();
  const updatePartType = useUpdatePartType();
  const addTagsQuery = useAddTags();
  const getPartTypeById = useGetOnePartType(partTypeId);
  const getStructuresByTypeCategoryCodeAndTypeCode = useGetStructuresByTypeCategoryCodeAndTypeCode(StructureTypeCategory.System, NodeType.Client);
  const getNodeChildrenByNodeType = useGetNodeChildrenByNodeType(partType.selectedParentId, parentSelector.queryNodeType);
  const createChildPartType = useCreateChildPartType(partTypeId);
  const updateChildPartType = useUpdateChildPartType(partTypeId);
  const deleteChildPartType = useDeleteChildPartType(partTypeId);
  const getPartTypeShipmentDataByPartTypeIdAndShipmentStatus = useGetPartTypeShipmentDataByPartTypeIdAndShipmentStatus(partTypeId, partType.serialised === SerialisedType.Serialised, `${ShipmentStatusCodes.Pending},${ShipmentStatusCodes.InTransit}`);
  const getOnePartTypeOnClose = useGetOnePartTypeOnClose();
  const uploadedFilesQuery = useGetUploadedFiles(partTypeId, Folders.PartTypesAttachments, true);
  const createWarranty = useCreateWarranty();
  const updateWarranty = useUpdateWarranty();
  const createSupplier = useCreateSupplier();
  const updateSupplier = useUpdateSupplier();
  const createSFHware = useCreateSFHware();
  const updateSFHware = useUpdateSFHware();
  const getAllConfigurationHistory = useGetAllConfigurationHistory(partTypeId);
  const createRepairPricing = useCreateRepairPricing();
  const updateRepairPricing = useUpdateRepairPricing();
  const createSalePricing = useCreateSalePricing();
  const updateSalePricing = useUpdateSalePricing();
  const uploadedFileDataByKey = useGetUploadedFilePresignedUrlById(selectedFileActionData.id, selectedFileActionData.contentDispositionType);

  TabHandler(isNew ? `Add Part Type | ${PLATFORM_NAME}` : `Part Type: ${id} | Part Types | ${PLATFORM_NAME}`);
  
  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    isNew && setPartType(SAMPLE_PART_TYPE);
  }, [isNew]);

  useEffect(() => {
    getStructuresByTypeCategoryCodeAndTypeCode.refetch();

    setParentSelector({ ...parentSelector, queryNodeType: NodeType.Contract });
    
    if (partType.selectedParentId !== '') {
      getNodeChildrenByNodeType.refetch();
    }
  }, []);

  useEffect(() => {
    selectedFileActionData.id && uploadedFileDataByKey.refetch();
  }, [selectedFileActionData.id, selectedFileActionData.contentDispositionType]);

  useEffect(() => {
    if (uploadedFileDataByKey.data && selectedFileActionData.mimeType.split('/').includes('image'), partTypeValidator.openImage) {
      if (uploadedFileDataByKey.data?.url) {
        setFullImage(uploadedFileDataByKey.data?.url);
        setPartTypeValidator({ ...partTypeValidator, openImage: true });
      }
    }

    if (uploadedFileDataByKey.data && !selectedFileActionData.mimeType.split('/').includes('image')) {
      const link = document.createElement('a');
      link.href = uploadedFileDataByKey.data?.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [uploadedFileDataByKey.data, selectedFileActionData.mimeType, partTypeValidator.openImage]);

  useEffect(() => {
    getAllConfigurationHistory.data && setConfigurationHistoryList(getAllConfigurationHistory.data);
  }, [getAllConfigurationHistory.data]);

  useEffect(() => {
    getPartTypeShipmentDataByPartTypeIdAndShipmentStatus.data && setPendingTransfersList(getPartTypeShipmentDataByPartTypeIdAndShipmentStatus.data);
  }, [getPartTypeShipmentDataByPartTypeIdAndShipmentStatus.data]);

  useEffect(() => {
    getPartTypeShipmentDataByPartTypeIdAndShipmentStatus.refetch();
  }, [partType.serialised]);

  useEffect(() => {
    serviceTagsQuery.data && setAllTags(serviceTagsQuery.data);
  }, [serviceTagsQuery.data]);

  // Get all partTypeCategories
  useEffect(() => {
    getAllPartTypeCategories.data && setPartTypeCategoryList(
      getAllPartTypeCategories.data.map(item => ({
        value: item.code,
        label: item.name
      }))
    );
  }, [getAllPartTypeCategories.data]);

  useEffect(() => {
    if (!isNew && getPartTypeById.data) {
      setPartType({
        ...getPartTypeById.data,
        selectedParentId: getPartTypeById.data.selectedClient,
        selectedTags: mapSavedTags(getPartTypeById.data.tags),
      });
    }
  }, [getPartTypeById.data, isNew, partTypeId]);

  const getPreviousPartType = async () => {
    const defaultPartType: any = partTypeId && await getOnePartTypeOnClose.mutateAsync(partTypeId);
    setPreviousPartType(defaultPartType);
  };

  useEffect(() => {
    if (!isNew) {
      getPreviousPartType();
    }
  }, [isNew, partTypeId, partType.selectedClient, partType.selectedContract]);

  useEffect(() => {
    if (getStructuresByTypeCategoryCodeAndTypeCode.data) { 
      const clients = getStructuresByTypeCategoryCodeAndTypeCode.data.map(item => ({ 
        value: item.id.toString(), 
        label: item.name,
        disabled: !item.isActive 
      }));

      setParentSelector({ ...parentSelector, clients });
    }
  }, [getStructuresByTypeCategoryCodeAndTypeCode.data]);

  useEffect(() => {
    if (getNodeChildrenByNodeType.data && parentSelector.queryNodeType === NodeType.Contract) {
      const contracts = ANY_ITEM_ARRAY.concat(getNodeChildrenByNodeType.data.map(item => ({
        value: item.id.toString(),
        label: item.name,
        disabled: !item.isActive 
      })));

      setParentSelector({ ...parentSelector, contracts });
    }
  }, [getNodeChildrenByNodeType.data]);

  useEffect(() => {
    partType.selectedParentId && parentSelector.queryNodeType && getNodeChildrenByNodeType.refetch();
  }, [partType.selectedParentId, parentSelector.queryNodeType]);

  useEffect(() => {
    !isNew && setPartTypeValidator({ ...partTypeValidator, isActiveChangeNotes: compareWithPrevious(previousPartType, partType) });
  }, [isNew, previousPartType.name, partType.name, partType.number, partType.manufacturerNodeId, partType.selectedClient, partType.selectedContract, partType.notes, partType.isActive]);

  const handleSave = async () => {
    // Validations
    const isEmptyName = isInputFieldEmpty(partType.name);
    const isEmptyClientContract = clientContractValidator(partType.selectedClient, partType.selectedContract);
    const isEmptyManufacturer = manufactuterValidator(partType.manufacturerNodeId);
    const validateConfigurationChangeNotes = partTypeValidator.isActiveChangeNotes ? characterLengthValidation(partType.configurationChangeNotes, CONFIGURATION_CHANGE_NOTES_LENGTH) : '';
    const isCategoryEmpty = isDropDownEmpty(partType.categoryCode);

    if (isCategoryEmpty || isEmptyName || isEmptyClientContract || isEmptyManufacturer || validateConfigurationChangeNotes) {
      setPartTypeValidator({
        ...partTypeValidator,
        isEmptyName,
        isEmptyClientContract,
        isEmptyManufacturer,
        isCategoryEmpty,
        configurationChangeNotesHelperText: validateConfigurationChangeNotes
      });
      return;
    }

    // Tags Integrations
    const existingTags = getSelectedExistingTagsMapped(partType.selectedTags);
    const freshTags = getNewTagsMapped(newTags);

    const savedData: any = {
      name: partType.name,
      number: partType.number,
      manufacturerNodeId: partType.manufacturerNodeId,
      categoryCode: partType.categoryCode,
      applicableNodeIds: JSON.stringify(partType.applicableNodeIds),
      notes: partType.notes,
      configurationChangeNotes: partType.configurationChangeNotes,
      isActive: partType.isActive
    };

    // POST, PUT Integrations
    if (isNew) {
      const partType: any = await createPartType.mutateAsync(savedData);
      await addTagsQuery.mutateAsync({
        entityTypeId: EntityType.TYPE_PARTTYPES,
        entityId: partType.id,
        freshTags,
        existingTags
      });
      setPartTypeValidator({ ...partTypeValidator, openPartTypeSuccessToast: true });
      await timeout(1000);
      setPartTypeValidator({ ...partTypeValidator, isActiveChangeNotes: false });
      navigate(`/configure/partTypes/${partType.id}`);
    } else {
      savedData.id = id;

      await updatePartType.mutateAsync(savedData);
      await addTagsQuery.mutateAsync({
        entityTypeId: EntityType.TYPE_PARTTYPES,
        entityId: id,
        freshTags,
        existingTags
      });

      const updatedPartType: any = await getOnePartTypeOnClose.mutateAsync(partTypeId);

      setPartType({ 
        ...partType, 
        selectedParentId: updatedPartType.selectedClient, 
        selectedTags: mapSavedTags(updatedPartType.tags),
        name: updatedPartType.name,
        number: updatedPartType.number,
        manufacturerNodeId: updatedPartType.manufacturerNodeId,
        manufacturerNodeName: updatedPartType.manufacturerNodeName,
        categoryCode: updatedPartType.categoryCode,
        applicableNodeIds: updatedPartType.applicableNodeIds,
        notes: updatedPartType.notes,
        isActive: updatedPartType.isActive,
        selectedClient: updatedPartType.selectedClient,
        selectedContract: updatedPartType.selectedContract,
        fleetTag: updatedPartType.fleetTag,
        configurationChangeNotes: '',
        selectedParentHierarchy: getClientHierarchy(parentSelector.clients || [], parentSelector.contracts || [], updatedPartType.selectedClient || '', updatedPartType.selectedContract || [])
      });

      setPreviousPartType({
        ...previousPartType,
        name: updatedPartType.name,
        number: updatedPartType.number,
        manufacturerNodeId: updatedPartType.manufacturerNodeId,
        selectedClient: updatedPartType.selectedClient,
        selectedContract: updatedPartType.selectedContract,
        notes: updatedPartType.notes,
        isActive: updatedPartType.isActive,
      });

      setPartTypeValidator({ ...partTypeValidator, openPartTypeSuccessToast: true, configurationChangeNotesHelperText: '', isActiveChangeNotes: false });
    }
  };

  useEffect(() => {
    uploadedFilesQuery.data && setUploadedFiles(uploadedFilesQuery.data);
  }, [uploadedFilesQuery.data]);

  const onClickUploadedFiles = async (id: number, actionType: FileUploadActionType, mimeType = '') => {
    if (mimeType.split('/').includes('image')) {
      setPartTypeValidator({ ...partTypeValidator, openImage: true });
    }

    if (actionType === FileUploadActionType.Archive) {
      await deleteUploadedFile(id);
      const refetchUploadedFilesQuery = await uploadedFilesQuery.refetch();
      refetchUploadedFilesQuery.data && setUploadedFiles(refetchUploadedFilesQuery.data);
      return;
    }

    setSelectedFileActionData(
      {
        id: id,
        contentDispositionType: actionType === FileUploadActionType.Download || mimeType.startsWith(FileTypes.MSExcel) || mimeType.startsWith(FileTypes.OpenXml) ? ContentDispositionType.Attachment : ContentDispositionType.Inline,
        mimeType
      });
  };

  const setFileDetails = async (fileList: FileList) => {
    if (fileList.length > 0) {
      const formData = new FormData();
      const files = [...fileList];
      files.forEach((item) => formData.append('fileList', item));

      await uploadFiles(partTypeId, formData, Folders.PartTypesAttachments);
      const refetchUploadedFilesQuery = await uploadedFilesQuery.refetch();
      refetchUploadedFilesQuery.data && setUploadedFiles(refetchUploadedFilesQuery.data);
    }
  };

  const handleSaveWarranty = async (warrantyData: WarrantyData) => {
    // validations
    if (!warrantyData.name || !warrantyData.term.duration || isDropDownEmpty(warrantyData.term.type) || isDropDownEmpty(warrantyData.triggerTypeCode)) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'All fields for creating/updating in warranty are mandatory'
      });
      return 'error';
    }

    const duration = parseInt(warrantyData.term.duration);

    if (duration <= 0) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'The warranty duration must have a minimum value of 1.'
      });
      return 'error';
    }

    const updatedValue = {
      ...warrantyData,
      partTypeId: partTypeId,
      term: {
        ...warrantyData.term,
        duration: duration,
      },
    };

    if (updatedValue?.id) {
      const updatedWarranty: any = await updateWarranty.mutateAsync(updatedValue);
      const foundIndex = partType.warranties.findIndex(warranty => warranty.id == updatedWarranty.id);
      partType.warranties[foundIndex] = updatedWarranty;
    }
    else {
      const createdWarranty: any = await createWarranty.mutateAsync(updatedValue);
      partType.warranties.push(createdWarranty);
    }

    setPartTypeValidator({ ...partTypeValidator, openPartTypeSuccessToast: true });
  };

  const handleSaveSupplierPricing = async (supplierPricingData: SalesRepairSupplierData) => {
    if (!supplierPricingData.nodeId || supplierPricingData.nodeId === 0) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'Supplier Name is mandatory'
      });
      return 'error';
    }

    if (!supplierPricingData.pricePerPackage) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'Supplier Price Per Package is mandatory'
      });
      return 'error';
    }

    if (!supplierPricingData.quantity || Number(supplierPricingData.quantity) <= 0) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'Supplier Package Quantity must have value greater than zero'
      });
      return 'error';
    }

    supplierPricingData.partTypeId = partTypeId;

    if (supplierPricingData?.id) {
      const updatedSupplier: any = await updateSupplier.mutateAsync(supplierPricingData);
      const foundIndex = partType.supplierPricings.findIndex(supplierPricing => supplierPricing.id == updatedSupplier.id);
      partType.supplierPricings[foundIndex] = updatedSupplier;
    }
    else {
      const createdSupplier: any = await createSupplier.mutateAsync(supplierPricingData);
      if (createdSupplier.isDefault) {
        partType.supplierPricings.map((supplierPricingItem) => {
          if (supplierPricingItem.isDefault) {
            supplierPricingItem.isDefault = false;
          }
        });
      }
      partType.supplierPricings.push(createdSupplier);
    }

    setPartTypeValidator({ ...partTypeValidator, openPartTypeSuccessToast: true });
  };

  const handleSFHware = async (sfhWareData: SoftwareFirmwareHardwareData, header: string, type: string) => {
    // validations
    if (!sfhWareData.version) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: `Version field for creating ${header} is mandatory`
      });
      return 'error';
    }

    if (LengthValidation(sfhWareData.version, SFHWARE_VERSION_LENGTH)) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'Maximum character length of Version field is 100'
      });
      return 'error';
    }

    if (LengthValidation(sfhWareData.description || '', CONFIGURATION_CHANGE_NOTES_LENGTH)) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'Maximum character length of Description field is 200'
      });
      return 'error';
    }

    sfhWareData.partTypeId = partTypeId;
    sfhWareData.type = type;

    if (sfhWareData?.id) {
      const updatedSFHwareData: any = await updateSFHware.mutateAsync(sfhWareData);

      if (updatedSFHwareData.type === SFHwareTypeEnum.Software) {
        const foundIndex = partType.softwares.findIndex(software => software.id == updatedSFHwareData.id);
        partType.softwares[foundIndex] = updatedSFHwareData;
      } else if (updatedSFHwareData.type === SFHwareTypeEnum.Firmware) {
        const foundIndex = partType.firmwares.findIndex(firmware => firmware.id == updatedSFHwareData.id);
        partType.firmwares[foundIndex] = updatedSFHwareData;
      } else if (updatedSFHwareData.type === SFHwareTypeEnum.Hardware) {
        const foundIndex = partType.hardwares.findIndex(hardware => hardware.id == updatedSFHwareData.id);
        partType.hardwares[foundIndex] = updatedSFHwareData;
      }
    }
    else {
      const createdSFHwareData: any = await createSFHware.mutateAsync(sfhWareData);

      if (createdSFHwareData.type === SFHwareTypeEnum.Software) {
        partType.softwares.push(createdSFHwareData);
      } else if (createdSFHwareData.type === SFHwareTypeEnum.Firmware) {
        partType.firmwares.push(createdSFHwareData);
      } else if (createdSFHwareData.type === SFHwareTypeEnum.Hardware) {
        partType.hardwares.push(createdSFHwareData);
      }
    }

    setPartTypeValidator({ ...partTypeValidator, openPartTypeSuccessToast: true });
  };

  const handleSaveSalePricing = async (salePricingData: SalePricingObject, isNew: boolean) => {
    if (!salePricingData.regionType) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'The region type field for a Sale Pricing is mandatory'
      });
      return 'error';
    }

    if (salePricingData.price === 0) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'The price field for a Sale Pricing is mandatory.'
      });
      return 'error';
    }

    if (salePricingData.price < 0) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'The price field for a Sale Pricing must have a positive value.'
      });
      return 'error';
    }

    const newSalePricingData = { ...salePricingData, partTypeId };

    if (isNew) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...rest } = newSalePricingData;

      const createdSalePrice: any = await createSalePricing.mutateAsync(rest);
      if (createdSalePrice.isDefault) {
        partType.salePricings.map((salePricing) => {
          if (salePricing.isDefault) {
            salePricing.isDefault = false;
          }
        });
      }

      partType.salePricings.push(createdSalePrice);
    } else {
      const updatedSalePrice: any = await updateSalePricing.mutateAsync(newSalePricingData);
      const foundIndex = partType.salePricings.findIndex(salePricing => salePricing.id == updatedSalePrice.id);
      partType.salePricings[foundIndex] = updatedSalePrice;
    }

    setPartTypeValidator({ ...partTypeValidator, openPartTypeSuccessToast: true });
  };

  const handleSaveRepairPricing = async (repairPricingData: SalesRepairSupplierData, isNew: boolean) => {
    if (!repairPricingData.cost) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'Repair Cost field for creating Repair Pricing is mandatory'
      });
      return 'error';
    }

    if (!repairPricingData.quantity) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'Quantity field for creating Repair Pricing is mandatory.'
      });
      return 'error';
    }

    if (repairPricingData.price && parseInt(repairPricingData.price) <= 0) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'The price field for creating Repair Pricing must have positive values.'
      });
      return 'error';
    }

    if (parseInt(repairPricingData.cost) <= 0) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'The cost field for creating Repair Pricing must have positive values.'
      });
      return 'error';
    }

    if (parseInt(repairPricingData.quantity) <= 0) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'The quantity field for creating Repair Pricing must have a minimum value of 1.'
      });
      return 'error';
    }

    if (!repairPricingData.nodeId || repairPricingData.nodeId === 0) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'Repairer Location field mandatory'
      });
      return 'error';
    }

    const repairPricing = {
      id: repairPricingData.id,
      partTypeId: partTypeId,
      description: repairPricingData.description,
      nodeId: repairPricingData.nodeId,
      price: repairPricingData.price ? parseFloat(repairPricingData.price).toFixed(2) : 0.00,
      cost: parseFloat(repairPricingData.cost).toFixed(2),
      quantity: parseInt(repairPricingData.quantity),
      isDefault: repairPricingData.isDefault,
      isActive: repairPricingData.isActive
    };

    if (isNew) {
      delete repairPricing.id;
      const createdRepairPricing: any = await createRepairPricing.mutateAsync(repairPricing);
      if (createdRepairPricing.isDefault) {
        partType.repairPricings.map((repairPricingsItem) => {
          if (repairPricingsItem.isDefault) {
            repairPricingsItem.isDefault = false;
          }
        });
      }
      partType.repairPricings.push(createdRepairPricing);
    } else {
      const updatedRepairPricing: any = await updateRepairPricing.mutateAsync(repairPricing);
      const foundIndex = partType.repairPricings.findIndex(repairPricing => repairPricing.id == updatedRepairPricing.id);
      partType.repairPricings[foundIndex] = updatedRepairPricing;
    }

    setPartTypeValidator({ ...partTypeValidator, openPartTypeSuccessToast: true });
  };

  const handleChildPartTypeSave = async (bomData: BillOfMaterialType, isNew: boolean) => {
    // validations
    if (!bomData.childPartTypeId || !bomData.quantity) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'All fields for creating child part type are mandatory'
      });
      return 'error';
    }
    if (isNew && partType.billOfMaterials.some((obj) => bomData.childPartTypeId === obj.childPartTypeId)) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'Selected part type is already added as a child'
      });
      return 'error';
    }
    if (bomData.quantity <= 0) {
      setPartTypeValidator({
        ...partTypeValidator,
        showWarningToast: true,
        warningMessage: 'The quantity field for creating child part type must have a minimum value of 1.'
      });
      return 'error';
    }

    if (isNew) {
      const createdBOM: any = await createChildPartType.mutateAsync(bomData);
      partType.billOfMaterials.push(createdBOM);
    }
    else {
      const updatedBOM: any = await updateChildPartType.mutateAsync(bomData);
      const foundIndex = partType.billOfMaterials.findIndex(bom => bom.childPartTypeId == updatedBOM.childPartTypeId);
      partType.billOfMaterials[foundIndex] = updatedBOM;
    }

    setPartTypeValidator({ ...partTypeValidator, openPartTypeSuccessToast: true });
  };

  const handleChildPartTypeDelete = async (bom: BillOfMaterialType) => {
    await deleteChildPartType.mutateAsync({
      childPartTypeId: bom.childPartTypeId
    });

    const foundIndex = partType.billOfMaterials.findIndex(billOfMaterial => billOfMaterial.childPartTypeId == bom.childPartTypeId);
    delete partType.billOfMaterials[foundIndex];
  };

  const handleImageClose = () => {
    setPartTypeValidator({ ...partTypeValidator, openImage: false });
    setFullImage('');
  };

  if (!isNew && !getPartTypeById.data) {
    return getPartTypeById.isFetching ? <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid> : <NotFoundError></NotFoundError>;
  }

  return (
    <PageContainer>
      <RoleTypography variant={TypographyVariantProps.H5} fontWeight={600}>
        {isNew ? 'Add Part Type' : `Edit Part Type [${previousPartType.name}]`}
      </RoleTypography>
      <Grid container my={GRID_SPACING}>
        <Grid item xs={5}>
          <Grid item xs={12} mb={GRID_SPACING}>
            <TextField
              fullWidth
              required
              error={partTypeValidator.isEmptyName}
              helperText={partTypeValidator.isEmptyName ? NAME_HELPER_TEXT : ''}
              label="Name"
              value={partType.name}
              onChange={(name: string) => {
                setPartTypeValidator({ ...partTypeValidator, isEmptyName: isInputFieldEmpty(name) });
                setPartType({ ...partType, name });
              }}
            />
          </Grid>
          <Grid item xs={12} mb={GRID_SPACING}>
            <TextField
              fullWidth
              label="Part Number"
              value={partType.number}
              onChange={(number) => setPartType({ ...partType, number })}
            />
          </Grid>
          {isNew && <Grid item xs={12} mb={GRID_SPACING}>
            <LiveSearchBox
              title="Manufacturer Search"
              timeOffset={400}
              isError={!!partTypeValidator.isEmptyManufacturer}
              helperText={partTypeValidator.isEmptyManufacturer ? MANUFACTURER_HELPER_TEXT : ''}
              value={partType.manufacturerNodeName}
              onClearValue={() => {
                setPartType({ 
                  ...partType,
                  manufacturerNodeId: NaN,
                  manufacturerNodeName: ''
                });
              }}
              renderItem={(props: any, option: any) => {
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} />
                  </LiveSearchListItem>
                );
              }}
              onChange={async (locationNodeData: LocationNodeData) => {
                setPartType({ 
                  ...partType,
                  manufacturerNodeId: locationNodeData.id,
                  manufacturerNodeName: locationNodeData.name
                });
                setPartTypeValidator({
                  ...partTypeValidator,
                  isEmptyManufacturer: manufactuterValidator(locationNodeData.id)
                });
              }}
              onApiInvoke={async (name: string) => {
                return await searchNodeQuery.mutateAsync(name);
              }}
            />
          </Grid>}
          {!isNew &&
            <Grid container item xs={12} mt={2} mb={2} alignItems="center">
              <Grid item mr={1}>
                <Typography textColor={COLORS.Black} fontWeight={TypographyFontWeightProps.Bold} variant={TypographyVariantProps.Body1}>Manufacturer:</Typography>
              </Grid>
              <Grid item width="60%">
                <EditLiveSearchField
                  isEditable={true}
                  mainLocationId={partType.manufacturerNodeId}
                  value={partType.manufacturerNodeName}
                  isManufacturer={true}
                  onChange={async (locationNodeData: LocationNodeData) => {
                    setPartType({ ...partType, manufacturerNodeId: locationNodeData.id });
                  }}
                />
              </Grid>
            </Grid>
          }
          <Grid item xs={12} mb={GRID_SPACING}>
            <Tags
              allTags={allTags}
              selectedTags={partType.selectedTags}
              setSeletedTags={(selectedTags) => setPartType({ ...partType, selectedTags })}
              newTags={newTags}
              setNewTags={(val) => setNewTags(val)}
            />
          </Grid>
          <Grid item container mb={GRID_SPACING}>
            <Grid xs={12}>
              <SelectMenu
                required={true}
                disabled={!isNew}
                id="part-type-category"
                labelId="part-type-category"
                label="Part Type Category *"
                selectedValue={partType.categoryCode}
                onChange={(categoryCode) => setPartType({ ...partType, categoryCode })}
                items={partTypeCategoryList}
                validate={partTypeValidator.isCategoryEmpty}
                optionalLabelEnabled={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} mb={GRID_SPACING}>
            <SelectionButton
              key={partType.selectedParentHierarchy}
              label="Client/Contract Selection*"
              value={partType.selectedParentHierarchy}
              error={!!partTypeValidator.isEmptyClientContract}
              onClick={() => {
                setPartTypeValidator({ ...partTypeValidator, openParentSelector: true });
                getStructuresByTypeCategoryCodeAndTypeCode.refetch();
                setPartType({ ...partType, selectedParentId: partType.selectedClient });
                setParentSelector({ ...parentSelector, queryNodeType: NodeType.Contract });
              }}
            />
          </Grid>
          <Grid item xs={12} mb={GRID_SPACING}>
            <TextField
              fullWidth
              label="Notes"
              multiline
              rows={5}
              value={partType.notes}
              onChange={(notes) => setPartType({ ...partType, notes })}
            />
          </Grid>
          <Grid item xs={12} mb={1} ml={1}>
            <FormControlLabel
              control={
                <Switch 
                  onChange={(event) => setPartType({ ...partType, isActive: event.target.checked })} 
                  checked={partType.isActive} 
                  color={ColorProps.Success}
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item container xs={12} spacing={GRID_SPACING} mt={1} justifyContent="flex-end">
            {!isNew &&
              <PrintLabelWrapper entityId={Number(id) || 0} entityType={PrintLabelEntityTypes.PartType} />
            }
            <Button disabled={!isNew && !isUserHasPermission(ActionPermissions.Configure_Part_Type_Edit, permissions)} variant={ButtonVariantProps.Primary} onClick={handleSave}>
              Save
            </Button>
          </Grid>
          {!isNew && partTypeValidator.isActiveChangeNotes && <Grid item xs={12} mb={GRID_SPACING} mt={2}>
            <TextField
              fullWidth
              label="Configuration Change Notes (Optional)"
              multiline
              error={!!partTypeValidator.configurationChangeNotesHelperText}
              helperText={partTypeValidator.configurationChangeNotesHelperText}
              rows={3}
              onChange={(configurationChangeNotes) => {
                setPartTypeValidator({ ...partTypeValidator, configurationChangeNotesHelperText: characterLengthValidation(configurationChangeNotes, CONFIGURATION_CHANGE_NOTES_LENGTH) });
                setPartType({ ...partType, configurationChangeNotes });
              }}
            />
          </Grid>}
        </Grid>
        {!isNew && (
          <Grid item xs={6} ml={4}>
            <Grid mb={4}>
              <MultipleImageUploadFragment entityId={partTypeId} />
            </Grid>
            <Attachments
              rows={uploadedFiles}
              onClick={onClickUploadedFiles}
              fileTypes={PART_TYPES_ATTACHMENTS_MIME_TYPES.split(',')}
              maxFileSize={MaxFileSize}
              setFileDetails={setFileDetails}
            />
          </Grid>
        )}
        {!isNew && (
          <Grid item container xs={12} spacing={GRID_SPACING} ml={0} mt={10}>
            <ConfigurationHistory configurationHistoryData={configurationHistoryList} />
          </Grid>
        )}
      </Grid>
      {!isNew && partType.serialised === SerialisedType.Serialised &&
        <>
          <Grid my={GRID_SPACING}>
            <WarrantyPanel
              warrantyData={partType.warranties}
              onChange={(warranties: WarrantyData[]) => setPartType({ ...partType, warranties })}
              onSave={(warranty) => handleSaveWarranty(warranty)}
            />
          </Grid>
          <Grid my={GRID_SPACING}>
            <SoftwareFirmwareHardwarePanel
              header="Attributes"
              softwareData={partType.softwares}
              firmwareData={partType.firmwares}
              hardwareRevisionData={partType.hardwares}
              onSoftwareChange={(softwares) => setPartType({ ...partType, softwares })}
              onFirmwareChange={(firmwares) => setPartType({ ...partType, firmwares })}
              onHardwareRevisionChange={(hardwares) => setPartType({ ...partType, hardwares })}
              onSoftwareSave={(softwareData) => handleSFHware(softwareData, SFHwareHeaderEnum.Software, SFHwareTypeEnum.Software)}
              onFirmwareSave={(firmwareData) => handleSFHware(firmwareData, SFHwareHeaderEnum.Firmware, SFHwareTypeEnum.Firmware)}
              onHardwareRevisionSave={(hardwareData) => handleSFHware(hardwareData, SFHwareHeaderEnum.HardwareRevision, SFHwareTypeEnum.Hardware)}
            />
          </Grid>
        </>
      }
      {!isNew &&
        <Grid my={GRID_SPACING}>
          <SalesRepairSupplierPanel
            header="Pricing Details"
            salesPricingData={partType.salePricings}
            repairPricingData={partType.repairPricings}
            supplierPricingData={partType.supplierPricings}
            onSalePricingChange={(salePricings) => setPartType({ ...partType, salePricings })}
            onRepairPricingChange={(repairPricings) => setPartType({ ...partType, repairPricings })}
            onSupplierPricingChange={(supplierPricings) => setPartType({ ...partType, supplierPricings })}
            onSalePricingSave={handleSaveSalePricing}
            onRepairPricingSave={handleSaveRepairPricing}
            onSupplierPricingSave={(supplierPricingData) => handleSaveSupplierPricing(supplierPricingData)}
            recallSearchNodeQueryForRepairPricing={(name) => searchNodeQuery.mutateAsync(name)}
            recallSearchNodeQueryForSupplierPricing={(name) => searchNodeQuery.mutateAsync(name)}
            partTypeContract={partType.selectedContract.toString()}
            partTypeName={partType.name}
            contractOptions={parentSelector.contracts}
          />
        </Grid>
      }
      {!isNew && (
        <Grid item container xs={12} spacing={GRID_SPACING} ml={0} mt={10}>
          <PartTypePendingTransfers partTypePendingTransfersData={pendingTransfersList} />
        </Grid>
      )}
      {!isNew && partType.serialised === SerialisedType.Serialised &&
        <Grid my={GRID_SPACING}>
          <BillOfMaterials
            billOfMaterialsData={partType.billOfMaterials}
            parentPartTypeApplicableNodeIds={partType.applicableNodeIds}
            parentPartTypeId={partTypeId}
            onChange={(billOfMaterials: BillOfMaterialType[]) => setPartType({ ...partType, billOfMaterials })}
            onDelete={(val) => handleChildPartTypeDelete(val)}
            onSave={(val, isNew) => handleChildPartTypeSave(val, isNew)}
            isSerialised={true}
          />
        </Grid>
      }
      <ContractMultiSelector
        open={partTypeValidator.openParentSelector}
        client={partType.selectedClient}
        contract={partType.selectedContract}
        clients={parentSelector.clients}
        contracts={parentSelector.contracts}
        onChange={(selectedClient: string, selectedContract: Array<string>) => {
          partType.selectedContract.splice(0, partType.selectedContract.length);
          setPartType({
            ...partType,
            selectedClient,
            selectedContract,
            selectedParentId: selectedClient
          });
          setParentSelector({ ...parentSelector, queryNodeType: NodeType.Contract });
          setPartTypeValidator({
            ...partTypeValidator,
            isEmptyClientContract: clientContractValidator(selectedClient, selectedContract)
          });
        }}
        onSelect={() => {
          if (partType.selectedClient && partType.selectedContract.length > 0) {
            setPartType({
              ...partType,
              applicableNodeIds: leafNodeCreater(partType.selectedClient, partType.selectedContract),
              selectedParentHierarchy: getClientHierarchy(parentSelector.clients, parentSelector.contracts, partType.selectedClient, partType.selectedContract)
            });
          }
          setPartTypeValidator({ ...partTypeValidator, openParentSelector: false });
        }}
        onClose={async () => {
          const defaultPartType: any = partTypeId && await getOnePartTypeOnClose.mutateAsync(partTypeId);
        
          setPartType({
            ...partType,
            selectedClient: defaultPartType?.selectedClient || '',
            selectedContract: defaultPartType?.selectedContract || [],
            selectedParentId: defaultPartType?.selectedClient || '',
            selectedParentHierarchy: isNew ? '' : getClientHierarchy(parentSelector.clients, parentSelector.contracts, defaultPartType?.selectedClient, defaultPartType.selectedContract)
          });
          setPartTypeValidator({ ...partTypeValidator, openParentSelector: false });
        }}
      />
      <Snackbar
        open={partTypeValidator.openPartTypeSuccessToast}
        autoHideDuration={2000}
        message="Successfully Saved"
        onClose={() => setPartTypeValidator({ ...partTypeValidator, openPartTypeSuccessToast: false })}
      />
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
        open={partTypeValidator.showWarningToast}
        autoHideDuration={4000}
        onClose={() => setPartTypeValidator({ ...partTypeValidator, showWarningToast: false })}
      >
        <Alert 
          onClose={() => setPartTypeValidator({ ...partTypeValidator, showWarningToast: false })} 
          severity="warning"
        >
          <AlertTitle>Warning</AlertTitle>
          <Box>{partTypeValidator.warningMessage}</Box>
        </Alert>
      </Snackbar>
      <Modal
        open={partTypeValidator.openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid onMouseDown={handleImageClose}>
          {fullImage && <StyledImage src={fullImage} />}
        </Grid>
      </Modal>
    </PageContainer>
  );
};