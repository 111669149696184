import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, Grid, Stack, ListSubheader } from '@mui/material';
import { Accordian } from '../../../../components/atoms/Accordian';
import { Typography, TypographyVariantProps } from '../../../../components/atoms/Typography';
import { LiveSearchBox, LiveSearchListItem } from '../../../../components/atoms/LiveSearchBox';
import { ActionPermissions, LocationNodeData, NodeType, SiblingParentDetails, SizeProps } from '../../../../@types';
import { LocationSearchItem } from '../../../../components/molecules/LocationSearchItem';
import { useSearchNodeDispatch, useSearchService } from '../../../../queries/live-search-query';
import { TextField } from '../../../../components/atoms/TextField';
import { DispatchDetailsFragmentProps } from './DispatchDetailsFragment.props';
import { StyledBox, StyledButton } from './DispatchDetailsFragment.styles';
import { ButtonColorProps, ButtonVariantProps } from '../../../../components/atoms/Button';
import { SelectionButton } from '../../../../components/molecules/SelectionButton';
import { useGetDispatchDestinationSuggestions, useGetLoggedInSiblingParentDetails, useGetNodeChildrenByNodeType, useGetStructuresByTypeCategoryCodeAndTypeCode } from '../../../../queries/structure-query';
import { StructureTypeCategory } from '../../../../configs/enums';
import { DropdownItem } from '../../../../components/atoms/SelectMenu';
import { IdSearchItem } from '../../../../components/molecules/IdSearchItem';
import { ServiceData } from '../../../../@types/service-search.type';
import { getHasCodeForNodeSearch } from '../../../../utils/services';
import { SearchCode } from '../../../../@types/part.type';
import { Checkbox, CheckboxColorProps } from '../../../../components/atoms/Checkbox';
import { ContractMultiSelector } from '../../../../components/molecules/ContractMultiSelector';
import { getClientHierarchy, leafNodeCreater, leafNodeCreaterForLiveSearch } from '../../../../utils/clientContractHierarchy';
import { nameValidation } from '../../../../utils/common';
import { AddStockDetailFields } from '../../../../@types/shipment.type';
import { ANY_ITEM_ARRAY } from '../../../../constants/common';
import { useGetUserSystemPermissions } from '../../../../queries/user-query';
import { isUserHasPermission } from '../../../../configs/permissions';
import { PrintLabel } from '../../../../components/molecules/PintLabel';
import { PrintLabelEntityTypes } from '../../../../@types/print-label.type';
import { Chip } from '../../../../components/atoms/Chip';
import { COLORS } from '../../../../configs/colors';
import { getUniqueLocationsForDispatchDestination } from '../../../../utils/shipment';

export const DispatchDetailsFragment: React.FC<DispatchDetailsFragmentProps> = ({
  dispatchDetail,
  setDispatchDetail,
  isNew,
  helperText,
  setHelperText,
  prefillData,
  clientSelectionDisabled,
  linkedServiceIds,
  isDisabled
}) => {
  const navigate = useNavigate();
  const [openParentSelector, setOpenParentSelector] = useState(false);
  const [selectedParentId, setSelectedParentId] = useState('');
  const [queryNodeType, setQueryNodeType] = useState(NodeType.Client);
  const [clients, setClients] = useState<DropdownItem[]>([]);
  const [contracts, setContracts] = useState<DropdownItem[]>([]);
  const [hidePickUpButton, setHidePickUpButton] = useState(false);
  const [siblingParentDetails, setSiblingParentDetails] = useState<SiblingParentDetails>();
  const [isPreFilled, setIsPreFilled] = useState(false);
  const [isByPassReceiptAllowed, setIsByPassReceiptAllowed] = useState(false);

  const searchNodeQuery = useSearchNodeDispatch();
  const getStructuresByTypeCategoryCodeAndTypeCode = useGetStructuresByTypeCategoryCodeAndTypeCode(StructureTypeCategory.System, NodeType.Client);
  const getNodeChildrenByNodeType = useGetNodeChildrenByNodeType(selectedParentId, queryNodeType);
  const searchServiceQuery = useSearchService();
  const getLoggedInSiblingParentDetailsQuery = useGetLoggedInSiblingParentDetails();
  const getUserSystemPermissionsQuery = useGetUserSystemPermissions();
  const getDispatchDestinationSuggestions = useGetDispatchDestinationSuggestions(
    prefillData?.serviceId || 0,
    prefillData?.locationId || 0,
    prefillData?.subLocationNodeId || 0,
    prefillData?.primaryAllocationNodeId || 0,
    prefillData?.secondaryAllocationNodeId || 0
  );

  useEffect(() => {
    const permissions = getUserSystemPermissionsQuery.data;
    if (permissions) {
      const hasPermission = isUserHasPermission(ActionPermissions.Shipment_Bypass_Receipt, permissions);
      setIsByPassReceiptAllowed(hasPermission);
    }
  }, [getUserSystemPermissionsQuery.data]);

  useEffect(() => {
    isNew && getLoggedInSiblingParentDetailsQuery.refetch();
    isNew && getDispatchDestinationSuggestions.refetch();
  }, []);

  useEffect(() => {
    if (prefillData?.locationId && !siblingParentDetails?.parentDetails?.id) {
      setDispatchDetail && setDispatchDetail({
        ...dispatchDetail,
        source: siblingParentDetails?.siblingDetails?.name ?? '',
        sourceNodeId: siblingParentDetails?.siblingDetails?.id ?? 0
      });
    }
  }, [siblingParentDetails]);

  useEffect(() => {
    prefillData?.serviceId && handlePrefillServiceData();
  }, [prefillData?.serviceId]);

  useEffect(() => {
    getStructuresByTypeCategoryCodeAndTypeCode.refetch();
    onChangeNodeType(NodeType.Contract, dispatchDetail.client);
  }, [dispatchDetail.client]);

  useEffect(() => {
    if (searchServiceQuery.data && !isPreFilled && prefillData?.serviceId) {
      const service = searchServiceQuery.data.find((item) => item.id === prefillData?.serviceId);
      service && handleServiceSearchChange(service);
      setIsPreFilled(true);
    }
  }, [searchServiceQuery.data]);

  useEffect(() => {
    if (getLoggedInSiblingParentDetailsQuery.data) {
      const obj = getLoggedInSiblingParentDetailsQuery.data;
      obj.parentDetails?.id && setDispatchDetail && setDispatchDetail({
        ...dispatchDetail,
        source: obj.parentDetails?.name,
        sourceNodeId: obj.parentDetails?.id
      });
      setSiblingParentDetails(obj);
    }
  }, [getLoggedInSiblingParentDetailsQuery.data]);

  useEffect(() => {
    if (getStructuresByTypeCategoryCodeAndTypeCode.data) {
      const clientsList: Array<DropdownItem> = getStructuresByTypeCategoryCodeAndTypeCode.data?.map(item => ({
        value: item.id.toString(),
        label: item.name,
        disabled: !item.isActive
      }));
      setClients(clientsList);
    }
  }, [getStructuresByTypeCategoryCodeAndTypeCode.data]);

  useEffect(() => {
    if (getNodeChildrenByNodeType.data) {
      if (queryNodeType === NodeType.Contract) {
        const contractMenuList = getNodeChildrenByNodeType.data.map(item => ({
          value: item.id.toString(),
          label: item.name,
          disabled: !item.isActive
        }));
        const contractMenuListWithAny = ANY_ITEM_ARRAY.map((item) => ({ ...item, disabled: false })).concat(contractMenuList);
        setContracts(contractMenuListWithAny);
      }
    }
  }, [getNodeChildrenByNodeType.data]);

  useEffect(() => {
    if (selectedParentId && queryNodeType) {
      getNodeChildrenByNodeType.refetch();
    }
  }, [selectedParentId, queryNodeType]);

  useEffect(() => {
    if (dispatchDetail.client && contracts) {
      setDispatchDetail && setDispatchDetail({
        ...dispatchDetail,
        clientHierarchyName: getClientHierarchy(clients, contracts, dispatchDetail.client, dispatchDetail.contract)
      });
    }
  }, [contracts]);

  const onChangeNodeType = (nodeType: NodeType, parentId: string) => {
    setSelectedParentId(parentId);
    setQueryNodeType(nodeType);
  };

  const handlePickUp = () => {
    setDispatchDetail && setDispatchDetail({
      ...dispatchDetail,
      source: siblingParentDetails?.parentDetails?.name ?? '',
      sourceNodeId: siblingParentDetails?.parentDetails?.id ?? 0,
      destination: siblingParentDetails?.siblingDetails?.name ?? '',
      destinationNodeId: siblingParentDetails?.siblingDetails?.id ?? 0
    });
    setHidePickUpButton(true);
  };

  const handleServiceSearchChange = async (obj: ServiceData) => {
    const client = obj.client?.toString() || '';
    onChangeNodeType(NodeType.Contract, client);
    const contract = obj.contract ? [obj.contract.toString()] : [];
    setDispatchDetail && setDispatchDetail({
      ...dispatchDetail,
      client: client,
      contract: contract,
      clientHierarchy: JSON.stringify(leafNodeCreater(client, contract)),
      clientHierarchySearch: JSON.stringify(leafNodeCreaterForLiveSearch(client, contract)),
      clientHierarchyName: getClientHierarchy(clients, contracts, client, contract),
      serviceId: obj.id
    });
  };

  const handleServiceSearch = async (obj: string) => {
    return searchServiceQuery.mutateAsync(obj);
  };

  const handlePrefillServiceData = async () => {
    await handleServiceSearch(prefillData?.serviceId.toString() || '');
  };

  return (
    <Accordian title={
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography>Detail</Typography>
        </Grid>
      </Grid>
    } defaultExpanded={true}>
      <Grid container spacing={3}>
        <Grid item xs={isNew && !hidePickUpButton && !prefillData?.isFromServiceEdit ? 6 : 12}>
          <StyledBox>
            <LiveSearchBox
              disabled={!isNew && !prefillData?.isFromServiceEdit}
              isRequired={true}
              helperText={helperText.sourceHelperText}
              title="Source"
              timeOffset={400}
              value={dispatchDetail.source}
              onClearValue={() => { setDispatchDetail && setDispatchDetail({ ...dispatchDetail, source: '', sourceNodeId: NaN }); }}
              renderItem={(props: any, option: any) => {
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} />
                  </LiveSearchListItem>
                );
              }}
              onChange={(obj: LocationNodeData) => {
                setDispatchDetail && setDispatchDetail({
                  ...dispatchDetail,
                  source: obj.name.includes(SearchCode.AM_LID) ? obj.code : obj.name,
                  sourceNodeId: obj.id
                });
                setHidePickUpButton(true);
                setHelperText && setHelperText({ ...helperText, sourceHelperText: nameValidation(obj.code, AddStockDetailFields.SourceField) });
              }}
              onApiInvoke={async (name: string) => {
                const hasCode = getHasCodeForNodeSearch(name);
                return await searchNodeQuery.mutateAsync({
                  name,
                  hasCode: hasCode
                });
              }}
            />
          </StyledBox>
          <StyledBox mt={2}>
            <LiveSearchBox
              disabled={!isNew}
              isRequired={true}
              helperText={helperText.destinationHelperText}
              title="Destination"
              timeOffset={400}
              value={dispatchDetail.destination}
              onClearValue={() => { setDispatchDetail && setDispatchDetail({ ...dispatchDetail, destination: '', destinationNodeId: NaN }); }}
              renderItem={(props: any, option: any) => {
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} isDispatchDestinations />
                  </LiveSearchListItem>
                );
              }}
              onChange={(obj: LocationNodeData) => {
                setDispatchDetail && setDispatchDetail({
                  ...dispatchDetail,
                  destination: obj.name.includes(SearchCode.AM_LID) ? obj.code : obj.name,
                  destinationNodeId: obj.id
                });
                setHidePickUpButton(true);
                setHelperText && setHelperText({ ...helperText, destinationHelperText: nameValidation(obj.code, AddStockDetailFields.DestinationField) });
              }}
              onApiInvoke={async (name: string) => {
                const hasCode = getHasCodeForNodeSearch(name);
                const searchData = await searchNodeQuery.mutateAsync({
                  name,
                  hasCode: hasCode
                });
                return getUniqueLocationsForDispatchDestination(name,searchData,getDispatchDestinationSuggestions.data || []);
              }}
              defaultSuggetions={getDispatchDestinationSuggestions.data}
              groupBy={(option) => option.section}
              renderGroup={(params) => [
                <ListSubheader key={params.key} sx={{ backgroundColor: params.group === 'Structure' ? COLORS.Cyan : COLORS.LightCyan }}>
                  <Typography variant={TypographyVariantProps.Subtitle1} fontWeight={700}>{params.group}</Typography>
                </ListSubheader>,
                params.children,
              ]}
            />
          </StyledBox>
        </Grid>
        {isNew && !hidePickUpButton && !prefillData?.isFromServiceEdit &&
          <Grid item xs>
            <StyledBox>
              <StyledButton variant={ButtonVariantProps.Primary} color={ButtonColorProps.Info} onClick={handlePickUp}>
                Pick Up
              </StyledButton>
            </StyledBox>
          </Grid>
        }
        <Grid item xs={12} md={6}>
          <StyledBox>
            <TextField
              fullWidth
              label="Note"
              value={dispatchDetail.note}
              onChange={(note: string) => setDispatchDetail && setDispatchDetail({ ...dispatchDetail, note })}
              multiline
              rows={5}
            />
          </StyledBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledBox>
            <SelectionButton
              label="Client/ Contract"
              key={dispatchDetail.clientHierarchyName}
              value={dispatchDetail.clientHierarchyName ?? ''}
              onClick={() => {
                isNew && !clientSelectionDisabled && setOpenParentSelector(true);
                getStructuresByTypeCategoryCodeAndTypeCode.refetch();
                onChangeNodeType(NodeType.Contract, dispatchDetail.client);
              }}
              disabled={!(isNew && !clientSelectionDisabled)}
            />
          </StyledBox>
          <StyledBox mt={2}>
            {isNew ?
              <LiveSearchBox
                disabled={!isNew}
                title="Service Search"
                timeOffset={400}
                value={dispatchDetail?.serviceId ? dispatchDetail?.serviceId?.toString() : ''}
                onClearValue={() => { setDispatchDetail && setDispatchDetail({ ...dispatchDetail, serviceId: NaN }); }}
                renderItem={(props: any, option: any) => {
                  return (
                    <LiveSearchListItem {...props}>
                      <IdSearchItem data={option} />
                    </LiveSearchListItem>
                  );
                }}
                onChange={handleServiceSearchChange}
                onApiInvoke={handleServiceSearch}
              />
              : 
              <Autocomplete
                disabled
                multiple
                size={SizeProps.Small}
                options={linkedServiceIds ? linkedServiceIds : []}
                value={linkedServiceIds}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'Service Search'}
                    size={SizeProps.Small}
                  />
                )}
                renderTags={(value: number[]) =>
                  value.map((option: number, index: number) => (
                    <Chip label={option} key={index} size={SizeProps.Small} onClick={() => navigate(`/services/${option}`)}/>)
                  )
                }
              />
            }
          </StyledBox>
          {!isNew && dispatchDetail.id &&
              <StyledBox mt={2}>
                <PrintLabel entityId={dispatchDetail.id} entityType={PrintLabelEntityTypes.Shipment} />
              </StyledBox>
          }
        </Grid>
        <Grid item xs={12} md={6} width="100%">
          {isNew &&
              <Stack direction="row" width="100%">
                {isByPassReceiptAllowed && 
                  <Stack direction="row" alignItems="center" width="100%">
                    <Checkbox
                      color={CheckboxColorProps.Primary}
                      checked={dispatchDetail.bypassReceipt}
                      onChange={(event) => setDispatchDetail && setDispatchDetail({ ...dispatchDetail, bypassReceipt: event.target.checked })}
                    />
                    <Typography>Bypass Receipt</Typography>
                  </Stack>
                }
                <Stack direction="row" alignItems="center" width="100%">
                  <Checkbox
                    color={CheckboxColorProps.Primary}
                    checked={dispatchDetail.overrideConsumption}
                    onChange={(event) => setDispatchDetail && setDispatchDetail({ ...dispatchDetail, overrideConsumption: event.target.checked })}
                  />
                  <Typography>Override Consumption</Typography>
                </Stack>
              </Stack>
          }
          {!isNew &&
            <StyledBox>
              <TextField
                disabled={isDisabled}
                fullWidth
                label="Connote"
                value={dispatchDetail.connoteNo}
                onChange={(connoteNo: string) => setDispatchDetail && setDispatchDetail({ ...dispatchDetail, connoteNo })}
              />
            </StyledBox>
          }
          {dispatchDetail.shippingProviderName &&
            <StyledBox mt={2}>
              <TextField
                disabled
                fullWidth
                label="Courier"
                value={dispatchDetail.shippingProviderName }
              />
            </StyledBox>
          }
        </Grid>
      </Grid>
      <ContractMultiSelector
        open={openParentSelector}
        client={dispatchDetail.client}
        contract={dispatchDetail.contract}
        clients={clients}
        contracts={contracts}
        onChange={(clientVal: string, contractVal: Array<string>) => {
          dispatchDetail.contract.splice(0, dispatchDetail.contract.length);
          setSelectedParentId(clientVal);
          onChangeNodeType(NodeType.Contract, clientVal);
          setDispatchDetail && setDispatchDetail({
            ...dispatchDetail,
            client: clientVal,
            contract: contractVal,
            clientHierarchyName: ''
          });
        }}
        onSelect={() => {
          if (dispatchDetail.client && dispatchDetail.contract.length > 0) {
            setDispatchDetail && setDispatchDetail({
              ...dispatchDetail,
              serviceId: 0,
              clientHierarchy: JSON.stringify(leafNodeCreater(dispatchDetail.client, dispatchDetail.contract)),
              clientHierarchySearch: JSON.stringify(leafNodeCreaterForLiveSearch(dispatchDetail.client, dispatchDetail.contract)),
              clientHierarchyName: getClientHierarchy(clients, contracts, dispatchDetail.client, dispatchDetail.contract)
            });
          }
          setOpenParentSelector(false);
        }}
        onClose={() => {
          setSelectedParentId('');
          setOpenParentSelector(false);
        }}
      />
    </Accordian>
  );
};